import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigationMenu from "../../NavigationMenu";
import { GridContainer } from "../../../styles/GridContainer";
import SourcesHeader from "../SourcesHeader";
import styled from "styled-components";
import StationsMap from "../../map/StationMap";
import CurrentUser from "./queries/CurrentUser";
import { amsLastMessages } from "./Queries";
import OverviewTable from "../../OverviewTable";
import { columns } from "./amsOverviewColumns";

class Sources extends Component {
  state = {};

  render() {
    return (
      <AMSDiv>
        <GridContainer className="gridContainer">
          <SourcesHeader />
          <NavigationMenu className="navmenu" />
          <CurrentUser className="user" />
          <div className="map">
            <StationsMap queryTag={amsLastMessages} />
          </div>
          <div className="TableDiv">
            <OverviewTable queryTag={amsLastMessages} columns={columns} />
          </div>
        </GridContainer>
      </AMSDiv>
    );
  }
}

const AMSDiv = styled.div`

  .addStation {
    background-color: white;
  }

  .addStation {
    grid-row: 3;
    grid-column: 11;
  }

  .map {
    grid-column: 3 / span 9;
    display: block;
    margin-top: 1%;
    height: 400px;
    border: solid 1px #7d9cb8;
  }

  .TableDiv {
    grid-row: 4;
    grid-column: 3 / span 9;
`;

export default Sources;
