function getSkyboxSymbol(code) {
  if (Number.isInteger(code))
    return String.fromCodePoint("!".codePointAt(0) + code);
  return "+";
}

const WEATHER_SYMBOLS = new Map([
  [4, "-"],
  [5, "."],
  [10, "/"],
  [11, "0"],
  [12, "1"],
  [18, "2"],
  [50, "N"],
  [60, "X"],
  [70, "b"],
  [96, "|"],
  [99, "}"]
]);
function addWeatherSymbols(start, stop, char) {
  const idx = char.codePointAt(0) - start;
  for (let i = start; i < stop; i++) {
    WEATHER_SYMBOLS.set(i, String.fromCodePoint(idx + i));
  }
}
addWeatherSymbols(20, 28, "3");
addWeatherSymbols(28, 30, "<");
addWeatherSymbols(30, 36, "?");
addWeatherSymbols(40, 49, "E");
addWeatherSymbols(51, 59, "P");
addWeatherSymbols(61, 69, "Z");
addWeatherSymbols(71, 79, "d");
addWeatherSymbols(80, 88, "l");
addWeatherSymbols(89, 96, "t");

function getCurrentWeatherSymbol(code) {
  return WEATHER_SYMBOLS.get(code) || "";
}

const PREVIOUS_WEATHER_SYMBOLS = new Map(
  Array.from(Array(9), (_, i) => [i + 1, ">:BEOY{lu"[i]])
);
function getPreviousWeatherSymbol(code) {
  return PREVIOUS_WEATHER_SYMBOLS.get(code) || "";
}

function getTemperature(codeSign, codeValue) {
  if (Number.isInteger(codeValue))
    return `${codeSign === 1 ? "-" : ""}${codeValue / 10} °C`;
  return "";
}

function getVisability(code) {
  if (Number.isInteger(code)) {
    if (code === 0) return "Менее 0,1 км";
    else if (code === 89) return "Более 70 км";
    else if (code > 0 && code <= 50) return `${code / 10} км`;
    else if (code >= 56 && code <= 80) return `${code - 50} км`;
    else if (code >= 81 && code <= 88) return `${30 + (code - 80) * 5} км`;
  }
  return "";
}

function getPressure(code) {
  if (Number.isInteger(code)) {
    const val = (code / 10).toFixed(1);
    if (code === 0) return "1000.0 гПа";
    else if (code < 1000) return `10${code < 100 ? "0" : ""}${val} гПа`;
    else return `${val} гПа`;
  }
  return "";
}

function getPressureTrendValue(codeSign, codeValue) {
  if (Number.isInteger(codeSign) && Number.isInteger(codeValue)) {
    const val = (codeValue / 10).toFixed(1);
    if (codeSign === 4) return `${val} гПа`;
    return `${codeSign > 4 ? "-" : "+"}${val} гПа`;
  }
  return "";
}

function getPressureTrendSymbol(code) {
  if (Number.isInteger(code)) return String.fromCodePoint(161 + code);
  return "";
}

function getWindDirection(code) {
  switch (code) {
    case 0:
      return "Штиль";
    case 99:
      return "Переменное";
    default:
      const val = code * 10;
      return `${val - 5}° - ${val + 4}°`;
  }
}

function getPrecipitation(codeFlag, codeValue, codeTime) {
  if (
    Number.isInteger(codeFlag) &&
    Number.isInteger(codeValue) &&
    Number.isInteger(codeTime)
  ) {
    if (codeFlag === 3 || codeFlag === 4 || codeValue <= 0) return "";
    if (codeValue < 989) return `${codeValue} мм / ${codeTime}`;
    if (codeValue === 989) return `989 и более мм / ${codeTime}`;
    if (codeValue < 1000) return `0.${codeValue % 10} мм / ${codeTime}`;
  }
  return "";
}

function getCloudSymbol(type, code) {
  if (Number.isInteger(code) && code > 0 && code <= 9) {
    switch (type) {
      case "stratus":
        return String.fromCodePoint(169 + code);
      case "highStratus":
        return String.fromCodePoint(178 + code);
      case "cirrus":
        return String.fromCodePoint(187 + code);
      default:
        return "default";
    }
  }
  return "";
}

// коэффициент длины базы к длине перьев
// работают пока только коэффиценты 10 и 5
const BASE_LENGTH_TO_FEATH_LENGTH_COEF = 5.0;

function generateFeath(start, a, a_feath, l_feath, positon, size, dist_coef) {
  const x = start[0];
  const y = start[1];
  const length = l_feath * BASE_LENGTH_TO_FEATH_LENGTH_COEF;
  const percent_between = 12;
  let x_start =
    x +
    length *
      dist_coef *
      Math.cos(a) *
      (1 - ((positon - 1) * percent_between) / 100) *
      0.997;
  let y_start =
    y +
    length *
      Math.sin(a) *
      (1 - ((positon - 1) * percent_between) / 100) *
      0.997;
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [
        [x_start, y_start],
        [
          x_start + l_feath * 1 * size * dist_coef * Math.cos(a_feath),
          y_start + l_feath * 1 * size * Math.sin(a_feath)
        ]
      ]
    }
  };
}

function generatePoint(start, a, a_feath, l_feath, dist_coef) {
  const x = start[0];
  const y = start[1];
  const length = l_feath * BASE_LENGTH_TO_FEATH_LENGTH_COEF;
  //const percent_between = 12;
  let x_start = x + length * 2 * Math.cos(a) * 0.997;
  let y_start = y + length * Math.sin(a) * 0.997;
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [
        x_start + l_feath * 2 * dist_coef * Math.cos(a_feath),
        y_start + l_feath * 2 * Math.sin(a_feath)
      ]
    }
  };
}

function generatetriangle(start, a, a_feath, l_feath, positon, dist_coef) {
  const x = start[0];
  const y = start[1];
  const length = l_feath * BASE_LENGTH_TO_FEATH_LENGTH_COEF;
  const percent_between = 12;
  let position_change;
  switch (BASE_LENGTH_TO_FEATH_LENGTH_COEF) {
    case 5.0:
      position_change = 3;
      break;
    case 10.0:
      position_change = 2;
      break;
    default:
      position_change = 2;
      console.log("Wrong BaselengthToFeathLentghCoef");
  }
  let x_start =
    x +
    length *
      dist_coef *
      Math.cos(a) *
      (1 - ((positon - 1) * percent_between) / 100);
  let y_start =
    y + length * Math.sin(a) * (1 - ((positon - 1) * percent_between) / 100);
  return {
    type: "Feature",
    properties: {
      stroked: false,
      stroke: "#000000",
      "stroke-width": 2,
      "stroke-opacity": 1,
      fill: "#000000",
      "fill-opacity": 1
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [x_start, y_start],
          [
            x_start + l_feath * 2 * dist_coef * Math.cos(a_feath),
            y_start + l_feath * 2 * Math.sin(a_feath)
          ],
          [
            x +
              length *
                dist_coef *
                Math.cos(a) *
                (1 - ((positon - position_change) * percent_between) / 100),
            y +
              length *
                Math.sin(a) *
                (1 - ((positon - position_change) * percent_between) / 100)
          ],
          [x_start, y_start]
        ]
      ]
    }
  };
}

function generateX(start, a, l_feath, angle, angle_l, dist_coef) {
  const x = start[0];
  const y = start[1];
  const length = l_feath * BASE_LENGTH_TO_FEATH_LENGTH_COEF;

  let ax = ((90 - angle - angle_l) * Math.PI) / 180;
  let x_start = x + length * dist_coef * Math.cos(a);
  let y_start = y + length * Math.sin(a);
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [
        [x_start, y_start],
        [
          x_start + l_feath * dist_coef * Math.cos(ax),
          y_start + l_feath * Math.sin(ax)
        ]
      ]
    }
  };
}

function makeGeoJsonWind(start, angle, length, velocity) {
  const x = start[0];
  const y = start[1];
  const a = ((90 - angle) * Math.PI) / 180;
  const a_feath = ((90 - angle - 50) * Math.PI) / 180;
  const l_feath = length / BASE_LENGTH_TO_FEATH_LENGTH_COEF;
  const dist_coef = 1 / Math.cos((y * Math.PI) / 180);

  let base = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [
        [
          x + length * dist_coef * Math.cos(a) * 0.13,
          y + length * Math.sin(a) * 0.13
        ],
        [x + length * dist_coef * Math.cos(a), y + length * Math.sin(a)]
      ]
    }
  };
  let result = [];

  result.push(base);
  if (velocity < 0) {
    result.push(generateX(start, a, l_feath, angle, 50, dist_coef));
    result.push(generateX(start, a, l_feath, angle, 130, dist_coef));
    result.push(generateX(start, a, l_feath, angle, 230, dist_coef));
    result.push(generateX(start, a, l_feath, angle, 310, dist_coef));
    return result;
  }

  if (velocity === 1) {
    result.push(generatePoint(start, a, a_feath, l_feath, dist_coef));
    return result;
  }

  let triNum = Math.floor(velocity / 25);
  let longfeathNum = Math.floor((velocity % 25) / 5);
  let leftVelocity = velocity - triNum * 25 - longfeathNum * 5;

  let currentPosition = 1;
  if (triNum > 0) {
    for (let i = 1; i <= triNum; i++) {
      if (currentPosition === 1) currentPosition++;
      result.push(
        generatetriangle(start, a, a_feath, l_feath, currentPosition, dist_coef)
      );
      if (i === triNum) {
        switch (BASE_LENGTH_TO_FEATH_LENGTH_COEF) {
          case 10.0:
            currentPosition += BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            break;
          case 5.0:
            currentPosition += 1 + BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            break;
          default:
            currentPosition += BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            console.log("Wrong BaselengthToFeathLentghCoef");
            break;
        }
      } else {
        switch (BASE_LENGTH_TO_FEATH_LENGTH_COEF) {
          case 10.0:
            currentPosition += 0.2 + BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            break;
          case 5.0:
            currentPosition += 1.8 + BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            break;
          default:
            currentPosition += 0.2 + BASE_LENGTH_TO_FEATH_LENGTH_COEF / 10.0;
            console.log("Wrong BaselengthToFeathLentghCoef");
            break;
        }
      }
    }
  }
  if (longfeathNum > 0) {
    for (let i = 1; i <= longfeathNum; i++) {
      let xss = generateFeath(
        start,
        a,
        a_feath,
        l_feath,
        currentPosition,
        2,
        dist_coef
      );
      result.push(xss);
      currentPosition++;
    }
  }
  if (leftVelocity > 0) {
    switch (leftVelocity) {
      case 2:
      case 3:
        result.push(
          generateFeath(
            start,
            a,
            a_feath,
            l_feath,
            currentPosition,
            1,
            dist_coef
          )
        );
        break;
      case 4:
        result.push(
          generateFeath(
            start,
            a,
            a_feath,
            l_feath,
            currentPosition,
            2,
            dist_coef
          )
        );
        break;
      default:
        console.log(
          "ams-utils line 397 default case, something might be gone wrong."
        );
    }
  }

  return result;
}

export {
  getSkyboxSymbol,
  getCurrentWeatherSymbol,
  getPreviousWeatherSymbol,
  getTemperature,
  getWindDirection,
  getVisability,
  getPressure,
  getPressureTrendValue,
  getPressureTrendSymbol,
  getPrecipitation,
  getCloudSymbol,
  makeGeoJsonWind
};
