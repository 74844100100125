import React, { Component } from "react";
import { Query } from "react-apollo";
import Map from "pigeon-maps";
import { connect } from "react-redux";
import { stationStatus } from "../data-sources/ams/queries/chartFuncs";
import MapLegend from "./MapLegend";
import store from "../../store";
import { changeOverviewTable } from "../../actions/actions";

function mapTilerProvider(x, y, z, dpr) {
  return `https://api.maptiler.com/maps/basic/256/${z}/${x}/${y}.png?key=cGbnB1PDIF2eDZolStlv`;
}
class StationsMap extends Component {
  state = {};
  render() {
    const { queryTag } = this.props;

    const CustomMarker = ({ left, top, style, children, marker }) => (
      <div
        onClick={({ event, anchor, payload }) => {
          const clickedElem = children.props.children;
          if (clickedElem.props) {
            store.dispatch(changeOverviewTable(clickedElem.props.children));
          } else {
            store.dispatch(changeOverviewTable(clickedElem));
          }
        }}
        style={{
          position: "absolute",
          left: left - 16,
          top: top - 32,
          border: `2px black ${marker.stroke}`,
          width: 30,
          height: 30,
          background: marker.color,
          borderBottomLeftRadius: "100%",
          borderBottomRightRadius: "100%",
          ...(style || {}),
        }}
      >
        {children}
        <br />
        <span
          style={{
            display: "block",
            fontWeight: "bold",
            fontSize: 10,
            textAlign: "center",
            marginTop: -3,
          }}
        >
          {marker.auto && <span>A</span>}
          {marker.manual && <span>M</span>}
        </span>
      </div>
    );

    const queryName = queryTag.definitions[0].name.value;

    const variables = {
      token: localStorage.getItem("token"),
    };

    const MapRender = () => (
      <Query query={queryTag} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <p>{loading}</p>;
          if (error) return <p>error...</p>;

          const { coord } = this.props;

          return (
            <>
              <Map
                provider={mapTilerProvider}
                center={[coord.lat, coord.lon]}
                zoom={10}
                style={{ width: "100%" }}
                onClick={(e) => {
                  store.dispatch(changeOverviewTable(""));
                }}
              >
                {data[queryName].map(
                  ({
                    station: {
                      id,
                      name,
                      lat,
                      lon,
                      height,
                      delayTime,
                      auto,
                      manual,
                    },
                    message,
                  }) => (
                    <CustomMarker
                      marker={stationStatus(message, delayTime, auto, manual)}
                      key={id}
                      anchor={[lat, lon]}
                      payload={name}
                    >
                      {queryName === "pluvioLastMessages" ? (
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "rgba(232, 236, 241, 0.8)",
                            width: "90px",
                            justifyContent: "center",
                            marginLeft: "-100%",
                          }}
                        >
                          <span style={{ textAlign: "center" }}>{id}</span>
                        </div>
                      ) : (
                        <span
                          style={{
                            fontSize: 10.7,
                            fontWeight: "bold",
                            textAlign: "right",
                            color: "white",
                          }}
                        >
                          {id}
                        </span>
                      )}
                    </CustomMarker>
                  )
                )}
                <MapLegend />
              </Map>
            </>
          );
        }}
      </Query>
    );

    return <MapRender />;
  }
}

const mapStateToProps = (store) => {
  return {
    coord: store.stationMap.coord,
  };
};

export default connect(mapStateToProps)(StationsMap);
