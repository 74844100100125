import React, { Component } from "react";
import { connect } from "react-redux";
import { increaseNumber } from "../actions/actions";
import { Link } from "react-router-dom";

class Shlyapa extends Component {
  handleClick = () => {
    this.props.increaseNumberAction();
  };

  render() {
    const { name, number } = this.props;
    return (
      <div>
        <h2>{name}</h2>
        <h2>{number}</h2>
        <p>
          <Link to="/some">some page</Link>
        </p>
        <button onClick={this.handleClick}>clickMe</button>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    // name: store.other.name,
    // number: store.other.number
  };
};

const mapDispatchToProps = dispath => {
  return {
    increaseNumberAction: () => dispath(increaseNumber())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shlyapa);
