import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import { GridContainer } from "../styles/GridContainer";
import { Query } from "react-apollo";
import gql from "graphql-tag";

class Server extends Component {
  state = {};
  render() {
    const ExchangeRates = () => (
      <Query
        query={gql`
          query($token: String!, $limit: Int) {
            stationsAmk(token: $token, filterSort: { limit: $limit }) {
              stationId
              name
              latitude
            }
            me(token: $token) {
              firstName
            }
          }
        `}
        variables={{
          token: localStorage.getItem("token"),

          limit: 5
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return console.log(error) + <p>error... </p>;
          return data.stationsAmk.map(({ stationId, name, latitude }) => (
            <div key={stationId}>
              <p>{`${stationId}: ${name} - ${latitude}`}</p>
            </div>
          ));
        }}
      </Query>
    );

    return (
      <GridContainer>
        <h1>Server</h1>
        <NavigationMenu />
        <Link className="link" to="/">
          <h6>main page</h6>
        </Link>
        <ul>
          <li>Состояние БД</li>
          <li>Состояние ftp</li>
          <li>Состояние почты</li>
        </ul>
        <ExchangeRates />
      </GridContainer>
    );
  }
}

export default Server;
