import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Query } from "react-apollo";

import { ME_QUERY } from "../../queries/queries";
import { getToken } from "../../utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Query query={ME_QUERY} variables={{ token: getToken() }}>
          {({ loading, error, data }) => {
            if (loading) return <></>;

            if (error)
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              );

            return <Component {...props} />;
          }}
        </Query>
      )}
    />
  );
};

export default PrivateRoute;
