import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import store from "../../../store";
import { changeStation, changeRegion } from "../../../actions/actions";

export const columns = [
  {
    Header: "Имя",
    accessor: "name",
    minWidth: 150,
    Cell: props => <span>{props.original.station.name}</span>
  },
  {
    Header: "#",
    accessor: "dd",
    minWidth: 150,
    Cell: props => <span>{props.original.station.id}</span>
  },
  {
    Header: "Координаты",
    Cell: props => (
      <span
        onClick={() => {
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
        }}
      >{`${props.original.station.lat}, ${props.original.station.lon}`}</span>
    )
  },
  {
    Header: "Последние данные",
    accessor: "P0P0P0P03",
    Cell: props => (
      <span>
        {moment(props.original.measuredAt).format("YYYY.MM.DD HH:mm")}
      </span>
    )
  },
  {
    Header: "Действия",
    accessor: "PPPP4",
    Cell: props => (
      <Link
        onClick={() => {
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
          store.dispatch(changeRegion(props.original.station.id));
        }}
        to={`levelinfo/${props.original.station.id}`}
        className="details"
      >
        {props.original.message ? "Подробнее" : ""}
      </Link>
    )
  }
];
