import React, { Component } from "react";
import { GridContainer } from "../../../styles/GridContainer";
import NavigationMenu from "../../NavigationMenu";
import SourcesHeader from "../SourcesHeader";
import styled from "styled-components";
import "react-table/react-table.css";
// import PluvioStationsTable from "./queries/PluvioStationsTable";
import CurrentUser from "../ams/queries/CurrentUser";
import StationMap from "../../map/StationMap";
import { pluvioLastMessages } from "./Queries";
import OverviewTable from "../../OverviewTable";
import constructColumns from "./pluvioOverviewColumns";

class RainGage extends Component {
  state = {};
  render() {
    return (
      <PluvioDiv>
        <GridContainer>
          <CurrentUser className="user" />
          <NavigationMenu />
          <SourcesHeader />
          <div className="map">
            <StationMap queryTag={pluvioLastMessages} />
          </div>
          <div className="overviewTable">
            <OverviewTable
              queryTag={pluvioLastMessages}
              columns={constructColumns()}
            />
          </div>
        </GridContainer>
      </PluvioDiv>
    );
  }
}

const PluvioDiv = styled.div`
  .map {
    grid-column: 3 / span 9;
    display: block;
    margin-top: 1%;
    height: 400px;
    border: solid 1px #7d9cb8;
  }

  .overviewTable {
    grid-column: 3 / span 9;
    grid-row: 3;
  }
`;

export default RainGage;
