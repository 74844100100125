import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

class NavigationMenu extends Component {
  state = {};
  render() {
    const currentUrl = window.location.pathname;

    const isSource = () => {
      if (currentUrl.includes("/sources/")) {
        return true;
      }
      return false;
    };

    return (
      <OptionList>
        <li
          style={{
            backgroundColor: "rgba(200, 200, 200, 0.9)",
          }}
        >
          <NavLink
            onClick={(e) => {
              e.preventDefault();
            }}
            activeClassName="active"
            to="/server"
          >
            Состояние сервера
          </NavLink>
        </li>
        <li
          style={{
            backgroundColor: "rgba(200, 200, 200, 0.9)",
          }}
        >
          <NavLink
            onClick={(e) => {
              e.preventDefault();
            }}
            activeClassName="active"
            to="/settings"
          >
            Настройки
          </NavLink>
        </li>
        <li>
          <NavLink
            exact={false}
            activeClassName="active"
            to="/sources/amsoverview"
            isActive={isSource}
          >
            Источники данных
          </NavLink>
        </li>
        <li>
          {/* <NavLink
            onClick={e => {
              e.preventDefault();
            }}
            activeClassName="active"
            to="/users"
          >
            Пользователи
          </NavLink> */}
          <a
            // style={{ padding: "0" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_SERVER_ENDPOINT}admin/auth/`}
          >
            Пользователи
          </a>
        </li>
        <li>
          {/* <NavLink
            onClick={e => {
               e.preventDefault();
            }}
            activeClassName="activebot"
            to="/journal"
          > */}
          <a
            // style={{ padding: "0" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_SERVER_ENDPOINT}admin/log/`}
          >
            Журнал
          </a>
          {/* </NavLink> */}
        </li>
      </OptionList>
    );
  }
}

const OptionList = styled.ul`
  grid-column: 1;
  grid-row: 2;
  width: 180px;
  height: 400px;
  margin: 0;
  padding: 0;
  list-style: none;
  --active-tab-color: #7d9cb8;
  background-color: white;
  border: 1px solid #7d9cb8;
  border-radius: 5px;
  z-index: 0;
  box-shadow: 25px 25px 10px rgba(154, 147, 140, 0.5);

  li:first-child {
    border-top: none;
  }

  li {
    margin-bottom: -1px;
    border-top: 1px solid #7d9cb8;
    font-weight: bold;
  }

  a {
    display: block;
    padding: 30px 14px;
    color: #2a455c;
    text-decoration: none;
  }

  .activebot {
    color: white;
    background-color: var(--active-tab-color);
    padding-bottom: 35px;
  }

  .active {
    color: white;
    background-color: var(--active-tab-color);
  }
`;

export default NavigationMenu;
