const TOKEN_KEY = "token";
const TOKEN_EXPIRES_IN_KEY = "serviceTokenExpiresIn";
const TOKEN_REFRESH_KEY = "serviceRefreshToken";

function setCredentials({ token, expiresIn, refreshToken }) {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(TOKEN_EXPIRES_IN_KEY, expiresIn);
  localStorage.setItem(TOKEN_REFRESH_KEY, refreshToken);
}

function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export { setCredentials, getToken };
