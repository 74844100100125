const initialState = {
  id: ""
};

export function overviewTableMapReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_OVERVIEW_TABLE":
      return { ...state, id: action.payload };

    default:
      return state;
  }
}
