import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  min-width: 100%;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 70px auto auto auto 40px;

  .link {
    grid-row: 6;
  }

  .loginform {
    position: absolute;
    grid-column: 2;
    grid-row: 1;
  }

  ::before {
    content: "Система диагностики сбора данных";
    grid-column: 1 / span 3;
    grid-row: 1;
    color: #2a455c;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    margin-top: 3%;
  }

  h1 {
    padding: -10px 0 0 10px;
  }

  .amsInfoChart {
    grid-column: 2 / span 8;
    grid-row: 3;
  }

  .amkadd {
    grid-column: 6;
    grid-row: 3;
  }

  .amslink {
    grid-column: 12;
    grid-row: 2;
    justify-self: end;
  }

  .mp-link {
    width: 150;
  }

  /* @media (max-width: 1000px) {
    width: 800px;
  } */
`;

export { GridContainer };
