import React, { Component } from "react";
import styled from "styled-components";
import StationsSelection from "./queries/StationSelection";
import StationsMap from "../../map/StationMap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  changeDateTo,
  changeDateFrom,
  changeLoadedTo,
  changeLoadedFrom,
} from "../../../actions/actions";
import { amsLastMessages } from "./Queries";

class AmsDataSorter extends Component {
  state = {
    inputDateFrom: this.props.dateFrom,
    inputDateTo: this.props.dateTo,
  };
  render() {
    const { inputDateFrom, inputDateTo } = this.state;
    const { id, dateFrom, dateTo } = this.props;

    console.log(
      `${
        process.env.REACT_APP_SERVER_ENDPOINT
      }api/files/ams/csv/?token=${localStorage.getItem(
        "token"
      )}&sid=${id}&since=${dateFrom}T00&to=${dateTo}T00`
    );

    const isSubmitable = inputDateFrom === dateFrom && inputDateTo === dateTo;

    return (
      <DataPanel className="DataSorter">
        <form>
          <label>Данные по станции: </label>
          <br />
          <StationsSelection initid={id} />
          <br />
          <label>На период с:</label>
          <br />
          <input
            type="date"
            name="dfrom"
            value={inputDateFrom || dateFrom}
            onChange={(e) => {
              this.setState({ inputDateFrom: e.target.value });
            }}
          />
          <br />
          <label>по:</label>
          <br />
          <input
            type="date"
            name="dto"
            value={inputDateTo || dateTo}
            onChange={(e) => {
              this.setState({ inputDateTo: e.target.value });
            }}
          />
          <br />
          <button
            className={isSubmitable ? "submit" : "notSubmit"}
            onClick={(e) => {
              e.preventDefault();
              this.props.changeDateFromAction(inputDateFrom || dateFrom);
              this.props.changeDateToAction(inputDateTo || dateTo);
              console.log(isSubmitable);
            }}
          >
            Подтвердить
          </button>
        </form>
        <div className="map">
          <StationsMap queryTag={amsLastMessages} />
        </div>
        <Link className="amslink" to="/sources/amsoverview">
          Показать все станции
        </Link>
        <a
          className="download"
          href={`${
            process.env.REACT_APP_SERVER_ENDPOINT
          }api/files/ams/csv/?token=${localStorage.getItem(
            "token"
          )}&sid=${id}&since=${dateFrom}T00&to=${dateTo}T00`}
        >
          Экспорт в CSV
        </a>
      </DataPanel>
    );
  }
}

const DataPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto auto;
  font-weight: bold;
  color: #2a455c;
  width: 100%;
  height: 98%;

  form {
    grid-row: 1;
    grid-column: 1;
  }

  .map {
    grid-column: 3 / span 10;
    grid-row: 1 / span 3;
    border: solid 1px #7d9cb8;
  }
  input {
    border: solid 1px #7d9cb8;
    color: #2a455c;
  }

  .selectid {
    grid-column: 2;
    grid-row: 1;
    line-height: 10px;
    justify-self: left;
    align-self: center;
    background-color: white;
    color: #2a455c;
    height: 22px;
    border: solid 1px #7d9cb8;
    font-weight: 500;
  }

  .submit {
    color: #2a455c;
    border-radius: 2px;
    /* box-shadow: 5px 5px 15px gray; */
    background-image: linear-gradient(to bottom right, white 40%, #e4e4e4);
    border: 1px solid #7d9cb8;
    margin-top: 5px;
  }

  .notSubmit {
    color: #2a455c;
    border-radius: 2px;
    /* box-shadow: 5px 5px 15px gray; */
    background-image: linear-gradient(to bottom right, white 40%, #e4e4e4);
    border: 1px dashed red;
    margin-top: 5px;
  }

  .download {
    align-self: end;
    grid-column: 1;
    grid-row: 1;
    display: block;
    text-decoration: none;
    border: 1px solid #2a455c;
    height: 35px;
    width: 70px;
    text-align: center;
    color: #2a455c;
    border-radius: 2px;
    box-shadow: 5px 5px 15px gray;
    background-image: linear-gradient(to bottom right, white 40%, #e4e4e4);
  }

  .amslink {
    align-self: end;
    justify-self: start;
    grid-column: 1;
    grid-row: 3;
    display: block;
    /* margin-top: 5%; */
    text-decoration: none;
    border: 1px solid #2a455c;
    height: 45px;
    width: 70px;
    text-align: center;
    padding-top: 1px;
    line-height: 1em;
    color: #2a455c;
    border-radius: 2px;
    box-shadow: 5px 5px 15px gray;
    background-image: linear-gradient(to bottom right, white 40%, #e4e4e4);
  }
`;

const mapStateToProps = (store) => {
  return {
    id: store.infoSelectionTable.id,
    dateTo: store.dataSorter.dateTo,
    dateFrom: store.dataSorter.dateFrom,
    loadedTo: store.dataSorter.loadedTo,
    loadedFrom: store.dataSorter.loadedFrom,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDateFromAction: (dateFrom) => dispatch(changeDateFrom(dateFrom)),
    changeDateToAction: (dateTo) => dispatch(changeDateTo(dateTo)),
    changeLoadedToAction: (loadedTo) => dispatch(changeLoadedTo(loadedTo)),
    changeLoadedFromAction: (loadedFrom) =>
      dispatch(changeLoadedFrom(loadedFrom)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AmsDataSorter);
