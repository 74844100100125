import React from "react";

import logo from "../../images/login/logo.png";
import { Background, Header, Title, Logo, LoginInputDataForm } from "./login";
import LoginForm from "../data-sources/ams/queries/LoginForm";

const Login = props => {
  return (
    <Background>
      <Header>
        <Title>Метеорологическая Информационная Система</Title>
        <Logo src={logo} alt="Росгидромет СЗ УГМС" />
      </Header>
      <LoginInputDataForm>
        <LoginForm
          refferFrom={props.location.state || { from: { pathname: "/" } }}
        />
      </LoginInputDataForm>
    </Background>
  );
};

export default Login;
