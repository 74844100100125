import styled from "styled-components";

const Row = styled.div`
  display: flex;
`;

const CenteredRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export { Row, CenteredRow };
