import React, { Component } from "react";
import styled from "styled-components";
import NavigationMenu from "../../../NavigationMenu";
import SourcesHeader from "../../SourcesHeader";
import CurrentUser from "../../ams/queries/CurrentUser";
import { GridContainer } from "../../../../styles/GridContainer";
import RadiationDataSorter from "./RadiationDataSorter";
import RadiationInfoTable from "./RadiationInfoTable";

class RadiationInfo extends Component {
  state = {};

  render() {
    const { id } = this.props.match.params;

    return (
      <StylingDiv>
        <GridContainer>
          <CurrentUser />
          <NavigationMenu />
          <SourcesHeader />
          <RadiationDataSorter id={id} />
          <div className="amsInfoChart">{/* <InfoChart /> */}</div>
          <div className="InfoTable">
            <RadiationInfoTable />
          </div>
        </GridContainer>
      </StylingDiv>
    );
  }
}

const StylingDiv = styled.div`
  .amsInfoChart {
    grid-column: 3 / span 9;
    height: 600px;
  }

  .DataSorter {
    grid-column: 3 / span 9;
    grid-row: 2;
    margin-top: 1%;
  }

  .InfoTable {
    width: 100%;
    grid-column: 3 / span 9;
    grid-row: 3;
    /* margin-top: 750px; */
  }
`;

export default RadiationInfo;
