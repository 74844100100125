export function increaseNumber() {
  return {
    type: "INCREASE_NUMBER"
  };
}

export function changeRegion(id) {
  return {
    type: "CHANGE_REGION",
    payload: id
  };
}

export function changePluvioId(pluvioId) {
  return {
    type: "CHANGE_PLUVIO_ID",
    payload: pluvioId
  };
}

export function changeBufrId(pluvioId) {
  return {
    type: "CHANGE_BUFR_ID",
    payload: pluvioId
  };
}

export function changeDateFrom(dateFrom) {
  return {
    type: "CHANGE_DFROM",
    payload: dateFrom
  };
}

export function changeDateTo(dateTo) {
  return {
    type: "CHANGE_DTO",
    payload: dateTo
  };
}

export function changeLoadedTo(loadedTo) {
  return {
    type: "CHANGE_LTO",
    payload: loadedTo
  };
}

export function changeLoadedFrom(loadedFrom) {
  return {
    type: "CHANGE_LFROM",
    payload: loadedFrom
  };
}

export function changeStation(coord) {
  return {
    type: "CHANGE_STATION",
    payload: coord
  };
}

export function changeOverviewTable(id) {
  return {
    type: "CHANGE_OVERVIEW_TABLE",
    payload: id
  };
}
