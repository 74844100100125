import React, { Component } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import { changeRegion, changeStation } from "../../../actions/actions";
import { levelsStations } from "./Queries";

class LevelStationSelection extends Component {
  handleChange = e => {
    this.props.changeRegionAction(e.target.value);
  };

  componentDidMount() {
    this.props.changeRegionAction(this.props.initid);
  }

  state = {};
  render() {
    const { id, changeStationAction } = this.props;

    const variables = {
      token: localStorage.getItem("token")
    };

    const StationData = () => (
      <Query query={levelsStations} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <option>{loading}</option>;
          if (error) return console.log(error) + <option>Error :</option>;

          console.log("data", data);

          const options = data.levelsStations.map(
            ({ id, name, lat, lon, height }) => (
              <option key={id} value={id}>
                {name} {id}
              </option>
            )
          );
          const sobj = data.levelsStations.find(v => v.id === id);

          function showStation(e) {
            const currStattion = data.levelsStations.find(
              v => v.id === e.target.value
            );
            console.log("e", e.target.value);
            changeStationAction({
              lat: currStattion.lat,
              lon: currStattion.lon
            });
          }

          return (
            <select
              className="selectid"
              defaultValue={sobj != null ? sobj.id : "72818"}
              onChange={e => {
                this.handleChange(e);
                showStation(e);
              }}
            >
              {options}
            </select>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStationAction: coord => dispatch(changeStation(coord)),
    changeRegionAction: id => dispatch(changeRegion(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelStationSelection);
