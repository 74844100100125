import React, { Component } from "react";
import { GridContainer } from "../../../../styles/GridContainer";
import NavigationMenu from "../../../NavigationMenu";
import SourcesHeader from "../../SourcesHeader";
import styled from "styled-components";
import CurrentUser from "../../ams/queries/CurrentUser";
import StationMap from "../../../map/StationMap";
import { bufrLastMessages } from "../Queries";
import OverviewTable from "../../../OverviewTable";
import { columns } from "./bufrOverviewColumns";

class Radar extends Component {
  state = {};
  render() {
    return (
      <BufrDiv>
        <GridContainer>
          <CurrentUser />
          <NavigationMenu />
          <SourcesHeader />
          <div className="map">
            <StationMap queryTag={bufrLastMessages} />
          </div>
          <div className="overviewTable">
            <OverviewTable queryTag={bufrLastMessages} columns={columns} />
          </div>
        </GridContainer>
      </BufrDiv>
    );
  }
}

const BufrDiv = styled.div`
  .map {
    grid-column: 3 / span 9;
    display: block;
    margin-top: 1%;
    height: 400px;
    border: solid 1px #7d9cb8;
  }

  .overviewTable {
    grid-column: 3 / span 9;
    grid-row: 3;
  }
`;
export default Radar;
