import React, { Component } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import { changeRegion, changeStation } from "../../../../actions/actions";
import { amsLastMessages } from "../Queries";

class StationsSelection extends Component {
  handleChange = e => {
    this.props.changeRegionAction(e.target.value);
  };

  componentDidMount() {
    this.props.changeRegionAction(this.props.initid);
  }

  state = {};
  render() {
    const { id, changeStationAction } = this.props;

    const variables = {
      token: localStorage.getItem("token")
    };

    const StationData = () => (
      <Query query={amsLastMessages} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <option>{loading}</option>;
          if (error) return console.log(error) + <option>Error :</option>;

          const options = data.amsLastMessages.map(
            ({ station: { id, name, lat, lon, height }, message }) => (
              <option key={id} value={id} data-lat={lat} data-lon={lon}>
                {name} {id}
              </option>
            )
          );
          const sobj = data.amsLastMessages.find(v => v.station.id === id);

          function showStation(e) {
            const currStattion = data.amsLastMessages.find(
              v => v.station.id === e.target.value
            ).station;
            changeStationAction({
              lat: currStattion.lat,
              lon: currStattion.lon
            });
          }

          return (
            <select
              className="selectid"
              defaultValue={sobj != null ? sobj.station.id : "26078"}
              onChange={e => {
                this.handleChange(e);
                showStation(e);
              }}
            >
              {options}
            </select>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeRegionAction: id => dispatch(changeRegion(id)),
    changeStationAction: coord => dispatch(changeStation(coord))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationsSelection);
