import gql from "graphql-tag";

const LOGIN_MUTATION = gql`
  mutation($password: String!, $username: String!) {
    login(password: $password, username: $username) {
      token
      expiresIn
      refreshToken
    }
  }
`;

export { LOGIN_MUTATION };
