import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { GridContainer } from '../styles/GridContainer';

class Settings extends Component {
  state = {};
  render() {
    return (
      <GridContainer>
        <h1>Settings</h1>
        <NavigationMenu />
        <Link className="link" to="/">
          <h6>main page</h6>
        </Link>
        <ul>
          <li>Единицы измерения скорости ветра – узлы или м/с</li>
          <li>Координаты – десятичные или градусы-минуты-секунды</li>
          <li>
            Период уведомлений – время от текущего момента в которое станция{' '}
            <br />
            подсвечивается красным если были пропуски. По умолчанию – 48 часов,
            задается в часах
          </li>
        </ul>
      </GridContainer>
    );
  }
}

export default Settings;
