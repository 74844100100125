const initialState = {
  currentStationTime: "Станция 1",
  parameter1: "ok",
  parameter2: "ok",
  parameter3: "ok"
};

export function infoTableReducer(state = initialState, action) {
  switch (action.type) {
    case "INCREASE_NUMBER":
      return { ...state, number: ++state.number };

    default:
      return state;
  }
}
