import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { BufrPaginationMessages } from "../Queries";
import InfoTable from "../../../InfoTable";

class BufrInfoTable extends Component {
  state = {};

  render() {
    const selectedId = `${this.props.bufrId}`;

    // const { loadedFrom, loadedTo, dateFrom, dateTo } = this.props;
    const { dateFrom, dateTo } = this.props;

    const types = {
      "DIFFERENTIAL REFLECTIVITY": "Дифференциальная отражающая способность",
      "DOPPLER MEAN VELOCITY (RADIAL)":
        "Средняя допплеровская (радиальная) скорость",
      "ECHO TOPS": "Верхняя граница радиолокационного отражения",
      "HORIZONTAL REFLECTIVITY": "Горизонтальная отражательная способность",
      "RADAR RAINFALL INTENSITY": "Интенсивность дождя по радиоционным данным",
      "TOTAL PRECIPITATION PAST 1 HOUR":
        "Сумма осадков за последний час (за последний 1 час)",
      "TOTAL PRECIPITATION PAST 3 HOUR":
        "Сумма осадков за последний час (за последние 3 часа)",
      "TOTAL PRECIPITATION PAST 6 HOUR":
        "Сумма осадков за последний час (за последние 6 часов)",
      "TOTAL PRECIPITATION PAST 12 HOUR":
        "Сумма осадков за последний час (за последние 12 часов)",
      "INTENSITY OF PRECIPITATION": "Интенсивность осадков"
    };

    const columns = [
      // {
      //   Header: "#",
      //   accessor: "count",
      //   maxWidth: 60
      // },
      {
        Header: "Время сообщения",
        accessor: "measured_at",
        minWidth: 150,
        Cell: props => (
          <span className="wrap">
            {moment(props.original.measuredAt * 1000).format(
              "YYYY.MM.DD, hh:mm"
            )}
          </span>
        )
      },
      {
        Header: props => <span className="wrap">Тип</span>,
        accessor: "data_type",
        minWidth: 150,
        Cell: props => (
          <span className="wrap">{types[props.original.dataType]}</span>
        )
      },
      {
        Header: "Скорость, м/с",
        accessor: "speedMotion"
      },
      // {
      //   Header: "Направление",
      //   accessor: "directionMotion"
      // },
      {
        Header: "Количество",
        accessor: "layers",
        Cell: props => <span>{props.original.layers}</span>
      },
      {
        Header: props => <span className="wrap">Высота 1</span>,
        accessor: "height_above_station_1",
        Cell: props => <span>{props.original.heightAboveStation1}</span>
      },
      {
        Header: props => <span className="wrap">Высота 2</span>,
        accessor: "height_above_station_2",
        Cell: props => <span>{props.original.heightAboveStation2}</span>
      }
    ];

    const variables = {
      token: localStorage.getItem("token"),
      stationId: selectedId,
      since: moment(dateFrom).format("YYYY-MM-DDT00"),
      to: moment(dateTo).format("YYYY-MM-DDT00")
    };

    return (
      <>
        <StyledTable className="InfoTable">
          <InfoTable
            key={selectedId}
            columns={columns}
            queryTag={BufrPaginationMessages}
            variables={variables}
          />
        </StyledTable>
      </>
    );
  }
}

const StyledTable = styled.div`
  border-collapse: collapse;
  text-align: center;
  color: #323232;
  border: none;
  width: 100%;
  margin-top: 45px;
  grid-column: 2 / span 8;
`;

const mapStateToProps = store => {
  return {
    bufrId: store.infoSelectionTable.bufrId,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo
  };
};

export default connect(mapStateToProps)(BufrInfoTable);
