import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import store from "../../../../store";
import { changeStation, changeRegion } from "../../../../actions/actions";

export const columns = [
  {
    Header: "Имя",
    accessor: "name",
    minWidth: 315,
    Cell: props => <span>{props.original.station.name}</span>
  },
  {
    Header: "#",
    accessor: "id",
    Cell: props => <span>{props.original.station.id}</span>
  },
  {
    Header: "Координаты",
    accessor: "coord",
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        onClick={() => {
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
        }}
      >{`${props.original.station.lat}, ${props.original.station.lon}`}</span>
    )
  },
  // {
  //   Header: "Таймаут",
  //   accessor: "timeout",
  //   sortable: false
  // },
  // {
  //   Header: "Ошибки",
  //   accessor: "errors",
  //   sortable: false
  // },
  // {
  //   Header: "Пропуски",
  //   accessor: "loss"
  // },
  {
    Header: "Последние данные",
    accessor: "lastMessage",
    minWidth: 150,
    Cell: props => (
      <span>
        {props.original.station
          ? moment(props.original.station.lastMessage).format("DD.MM.YY, HH:mm")
          : " - "}
      </span>
    )
  },
  {
    Header: "Действия",
    accessor: "actions",
    minHeight: 100,
    sortable: false,
    Cell: props => (
      <Link
        to={`radiationinfo/${props.original.station.id}`}
        className="details"
        onClick={() => {
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
          store.dispatch(changeRegion(props.original.station.id));
        }}
      >
        {props.original.message ? "Подробнее" : ""}
      </Link>
    )
  }
];
