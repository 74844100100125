import React, { Component } from "react";
import { Query } from "react-apollo";
import store from "../../../../store";
import { connect } from "react-redux";
import { changePluvioId, changeStation } from "../../../../actions/actions";
import { pluvioStations } from "../Queries";

class PluvioStationSelection extends Component {
  handleChange = e => {
    this.props.changePluvioIdAction(`${e.target.value}`);
  };

  componentDidMount() {
    this.props.changePluvioIdAction(`${this.props.initid}`);
  }

  state = {};
  render() {
    const variables = {
      token: localStorage.getItem("token")
    };

    const StationData = () => (
      <Query query={pluvioStations} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <option>{loading}</option>;
          if (error) return console.log(error) + <option>Error :</option>;

          const options = data.pluvioStations.map(({ id, name }) => (
            <option key={id} value={id}>
              {name} {id}
            </option>
          ));
          const sobj = data.pluvioStations.find(
            v => v.id === this.props.pluvioId
          );

          function showStation(e) {
            const currStattion = data.pluvioStations.find(
              v => v.id === e.target.value
            );
            store.dispatch(
              changeStation({
                lat: currStattion.lat,
                lon: currStattion.lon
              })
            );
          }

          return (
            <select
              className="selectid"
              defaultValue={sobj != null ? sobj.id : "0000001001"}
              onChange={e => {
                this.handleChange(e);
                showStation(e);
              }}
            >
              {options}
            </select>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const mapStateToProps = store => {
  return {
    pluvioId: store.infoSelectionTable.pluvioId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStationAction: coord => dispatch(changeStation(coord)),
    changePluvioIdAction: id => dispatch(changePluvioId(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PluvioStationSelection);
