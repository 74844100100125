import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Server from "./components/Server";
import Settings from "./components/Settings";
import AMSOverview from "./components/data-sources/ams/AMSOverview";
import Users from "./components/Users";
import Journal from "./components/Journal";
import AMSInfo from "./components/data-sources/ams/AMSInfo";
import Benthal from "./components/data-sources/benthal/Benthal";
import LevelOverview from "./components/data-sources/levelGage/LevelOverview";
import Radar from "./components/data-sources/radar/BufrOverview/BufrOverview";
import PluvioOverview from "./components/data-sources/rainGage/PluvioOverview";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import NotFound from "./components/NotFound";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginPage from "./components/LoginPage/LoginPage";
import Test from "./test/Test";
import PluvioInfo from "./components/data-sources/rainGage/PluvioInfo";
import BufrInfo from "./components/data-sources/radar/BufrInfo/BufrInfo";
import LevelInfo from "./components/data-sources/levelGage/LevelInfo";
import RadiationOverview from "./components/data-sources/radiation/RadiationOverview/RadiationOverview";
import RadiationInfo from "./components/data-sources/radiation/RadiationInfo/RadiationInfo";
import store from "./store";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_ENDPOINT}graphql/`
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Switch>
          <PrivateRoute path="/sources/amsoverview" component={AMSOverview} />
          <Route exact path="/" component={App} />
          <Route path="/login" component={LoginPage} />
          <PrivateRoute path="/server" component={Server} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/sources/amsinfo/:id" component={AMSInfo} />
          <PrivateRoute path="/sources/benthal" component={Benthal} />
          <PrivateRoute
            path="/sources/leveloverview"
            component={LevelOverview}
          />
          <PrivateRoute path="/sources/levelinfo/:id" component={LevelInfo} />
          <PrivateRoute path="/sources/bufroverview" component={Radar} />
          <PrivateRoute path="/sources/bufrinfo/:id" component={BufrInfo} />
          <PrivateRoute
            path="/sources/radiationoverview"
            component={RadiationOverview}
          />
          <PrivateRoute
            path="/sources/radiationinfo/:id"
            component={RadiationInfo}
          />
          <PrivateRoute
            path="/sources/pluviooverview"
            component={PluvioOverview}
          />
          <PrivateRoute path="/sources/pluvioinfo/:id" component={PluvioInfo} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/journal" component={Journal} />
          <PrivateRoute path="/test" component={Test} />
          <PrivateRoute exact path="*" component={NotFound} />
        </Switch>
      </ApolloProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
