import gql from "graphql-tag";

export const pluvioPaginationMessages = gql`
  query pluvioPaginationMessages(
    $token: String!
    $stationId: String!
    $page: Int
    $pageCount: Int
    $filterSort: FilterSortInput
  ) {
    pluvioPaginationMessages(
      token: $token
      stationId: $stationId
      page: $page
      pageCount: $pageCount
      filterSort: $filterSort
    ) {
      objects {
        id
        measuredAt
        accumulatedRtNrt
        accumulatedRtNrt1Min
        accumulatedTotalNrt
        bucketContentNrt
        intensity
        dailySum
        powerSupplyPluvio2
        relativeAir
        statusHeatingPluvio2
        statusPluvio2
        temperature
        temperatureLoadcellPluvio2Ec
        temperatureRimHeatingPluvio2Ec
      }
      page
      pages
      hasNext
    }
  }
`;

export const pluvioLastMessages = gql`
  query pluvioLastMessages($token: String!) {
    pluvioLastMessages(token: $token, isadmin: true) {
      station {
        id
        name
        lat
        lon
        height
      }
      message {
        measuredAt
        powerSupplyPluvio2
      }
    }
  }
`;

export const pluvioStations = gql`
  query pluvioStations($token: String!) {
    pluvioStations(token: $token) {
      id
      name
      lat
      lon
    }
  }
`;
