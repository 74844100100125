import gql from "graphql-tag";

export const radLastMessages = gql`
  query radLastMessages($token: String!) {
    radLastMessages(token: $token, isadmin: true) {
      station {
        id
        lat
        lon
        name
        height
      }
      message {
        measuredAt
      }
    }
  }
`;

export const radPaginationMessages = gql`
  query radPaginationMessages(
    $token: String!
    $stationId: String!
    $page: Int
    $pageCount: Int
    $filterSort: FilterSortInput
  ) {
    radPaginationMessages(
      token: $token
      stationId: $stationId
      page: $page
      pageCount: $pageCount
      filterSort: $filterSort
    ) {
      objects {
        measuredAt
        DD
        M
        HH
        T
        AAA
        RSUM
        RSWR
        filesIds
      }
      page
      pages
    }
  }
`;

export const radStations = gql`
  query radStations($token: String!) {
    radStations(token: $token) {
      name
      id
      lat
      lon
    }
  }
`;
