import React, { Component } from "react";
import { Query } from "react-apollo";
import styled from "styled-components";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { changeStation } from "../actions/actions";
import store from "../store";

class StationsData extends Component {
  state = {};
  render() {
    const variables = {
      token: localStorage.getItem("token")
    };

    const { queryTag, columns, id } = this.props;

    const queryName = queryTag.definitions[0].name.value;

    const StationData = () => (
      <Query query={queryTag} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <p>{loading}</p>;
          if (error) return console.log(error) + <tr>Error :</tr>;

          console.log(store.getState().overviewTableMap.id);

          const dataArr = data[queryName].filter(elem =>
            elem.station.id.includes(id)
          );

          return (
            <TableDiv>
              <ReactTable
                defaultPageSize={100}
                className="TableDiv"
                previousText={"Назад"}
                nextText={"Вперед"}
                pageText={"Страница"}
                rowsText={"строк"}
                ofText={"из"}
                data={dataArr}
                minRows={2}
                columns={columns}
              />
            </TableDiv>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const TableDiv = styled.div`
  text-align: center;
  font: 300 16px/18px Tahoma, sans-serif;
  color: #323232;

  .rt-table {
    background-color: white;
  }

  .rt-tr-group:nth-child(2n) {
    background: rgb(234, 250, 255);
  }

  .details {
    text-decoration: none;
    font-size: 0.8em;
    font-weight: 700;
    color: #333333;
  }

  .coord {
    cursor: pointer;
  }

  .details:hover {
    display: inline-block;
    transform: scale(1.2);
  }
`;
const mapStateToProps = store => {
  return {
    coord: store.stationMap.coord,
    id: store.overviewTableMap.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStationAction: coord => dispatch(changeStation(coord))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationsData);
