import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { radPaginationMessages } from "../Queries";
import InfoTable from "../../../InfoTable";

class RadiationInfoTable extends Component {
  state = {};

  render() {
    const selectedId = this.props.id;

    console.log("selectedId", selectedId);

    const { dateFrom, dateTo } = this.props;
    const queryTag = radPaginationMessages;

    const variables = {
      token: localStorage.getItem("token"),
      stationId: selectedId,
      since: moment(dateFrom).format("YYYY-MM-DDT00"),
      to: moment(dateTo).format("YYYY-MM-DDT23:59")
    };

    const columns = [
      {
        Header: "Время сообщения",
        accessor: "measuredAt",
        minWidth: 150,
        Cell: props => (
          <span>
            {moment(props.original.measuredAt * 1000).format("DD.MM.YY, HH:mm")}
          </span>
        )
      },
      {
        Header: "DD",
        accessor: "DD"
      },
      {
        Header: "M",
        accessor: "M"
      },
      {
        Header: "HH",
        accessor: "HH"
      },
      {
        Header: "T",
        accessor: "T"
      },
      {
        Header: "Данные радиационных наблюдений",
        accessor: "AAA",
        minWidth: 200,
        Cell: props =>
          props.original.AAA ? <span>{props.original.AAA} мк3в</span> : ""
      },
      {
        Header: "RSUM",
        accessor: "RSUM"
      },
      {
        Header: "RSWR",
        accessor: "RSWR"
      }
    ];

    return (
      <StyledTable className="InfoTable">
        <InfoTable
          key={selectedId}
          columns={columns}
          queryTag={queryTag}
          variables={variables}
        />
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  margin-top: 50px;
`;

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo
  };
};

export default connect(mapStateToProps)(RadiationInfoTable);
