import React from "react";
import { Redirect } from "react-router-dom";

import { Mutation } from "react-apollo";

import { LOGIN_MUTATION } from "../../../../mutations/mutations";
import { setCredentials } from "../../../../utils/auth";

import {
  LoginContainer,
  LoginRow,
  LoginLabel,
  LoginInput,
  LoginError,
  LoginButton
} from "../../../LoginPage/style";

function LoginForm(props) {
  let login, password;

  function constructError(error) {
    if (error) {
      return error.graphQLErrors.map((err, i) => {
        return <LoginError key={i}>{err.message}</LoginError>;
      });
    }
    return <></>;
  }

  return (
    <Mutation mutation={LOGIN_MUTATION}>
      {(loginAction, { loading, error, data }) => {
        if (data) {
          setCredentials(data.login);
          return <Redirect to={props.refferFrom.from.pathname} />;
        }

        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              loginAction({
                variables: {
                  username: login.value,
                  password: password.value
                }
              }).catch(error => {});
            }}
          >
            <LoginContainer>
              <LoginRow>
                <LoginLabel>Логин</LoginLabel>
                <LoginInput
                  ref={node => {
                    login = node;
                  }}
                />
              </LoginRow>
              <LoginRow>
                <LoginLabel>Пароль</LoginLabel>
                <LoginInput
                  type="password"
                  ref={node => {
                    password = node;
                  }}
                />
              </LoginRow>
              {constructError(error)}
              <LoginButton type="submit">Войти</LoginButton>
            </LoginContainer>
          </form>
        );
      }}
    </Mutation>
  );
}

export default LoginForm;
