import moment from "moment";

const initialState = {
  loadedFrom: moment()
    .subtract(34, "days")
    .format("YYYY-MM-DD"),
  loadedTo: moment()
    .add(4, "days")
    .format("YYYY-MM-DD"),
  dateFrom: moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD"),
  dateTo: moment().format("YYYY-MM-DD")
};

export function dataSorterReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_DFROM":
      console.log("I'm dateFrom reducer", action.payload);
      return { ...state, dateFrom: action.payload };

    case "CHANGE_DTO":
      console.log("I'm dateTo reducer");
      return { ...state, dateTo: action.payload };

    case "CHANGE_LFROM":
      let loadedFrom = action.payload.loadedFrom;
      const dateFrom = action.payload.dateFrom;

      if (loadedFrom >= dateFrom) {
        loadedFrom = moment(dateFrom)
          .subtract(8, "days")
          .format("YYYY-MM-DD");
      }
      return {
        ...state,
        loadedFrom: loadedFrom
      };

    case "CHANGE_LTO":
      let loadedTo = action.payload.loadedTo;
      const dateTo = action.payload.dateTo;

      if (loadedTo <= dateTo) {
        loadedTo = moment(dateTo)
          .add(8, "days")
          .format("YYYY-MM-DD");
      }
      return { ...state, loadedTo: loadedTo };

    default:
      return state;
  }
}
