import styled from "styled-components";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3a4661;
  padding: 15px 35px;
  border-radius: 10px;
`;

const LoginRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const LoginLabel = styled.span`
  font-family: "Europe", sans-serif;
  padding-right: 10px;
  font-size: 20px;
  color: #cecece;
  width: 85px;
  text-align: right;
  margin: 3px 0;
`;

const LoginInput = styled.input`
  background-color: #16223d;
  color: #cecece;
  border: none;
  font-family: "Europe", sans-serif;
  text-decoration: none;
  padding: 2px;
  font-size: 24px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
`;

const LoginError = styled.p`
  background-color: #16223d;
  color: #de7e7e;
  border: none;
  font-family: "Europe", sans-serif;
  padding: 6px 15px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
`;

const LoginButton = styled.button`
  background-color: #16223d;
  color: #cecece;
  font-family: "Europe", sans-serif;
  padding: 15px 35px;
  font-size: 28px;
  border-radius: 5px;
  width: 160px;
  text-align: center;
  border: none;
`;

export {
  LoginContainer,
  LoginRow,
  LoginLabel,
  LoginInput,
  LoginError,
  LoginButton
};
