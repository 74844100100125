import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import "../../../../../node_modules/react-vis/dist/style.css";
import {
  changeDateFrom,
  changeDateTo,
  changeLoadedFrom,
  changeLoadedTo
} from "../../../../actions/actions";
import moment from "moment";
// import back from "../../../../images/navigation/back.svg";
// import next from "../../../../images/navigation/next.svg";
import { PluvioChartGen } from "react-meteo-chart";

class PluvioInfoChart extends Component {
  state = {
    cross: [{ x: 1487624400000, y: 9 }, { x: 1487624400000, y: 19 }],
    showcross: false
  };

  render() {
    const { dateFrom, dateTo, stationId } = this.props;

    console.log("dateFrom in pluvio chart", dateFrom);
    const pluvioDateFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm");
    const pluvioDateTo = moment(dateTo).format("YYYY-MM-DDTHH:mm");
    console.log("pluvioDateFrom, pluvioDateTo", pluvioDateFrom, pluvioDateTo);

    return (
      <>
        <ChartDiv>
          <PluvioChartGen
            token={localStorage.getItem("token")}
            stationId={stationId}
            height={300}
            dateFrom={pluvioDateFrom}
            dateTo={pluvioDateTo}
            isAdmin={true}
            loadMessage={false}
          />
        </ChartDiv>
      </>
    );
  }
}

const ChartDiv = styled.div`
  width: 100%;
  height: 1200px;
  /* border: 1px solid #7d9cb8; */
  background-color: white;
  position: relative;

  .forward {
    right: 0;
  }

  .backward {
    margin-right: 100px;
  }

  button {
    position: absolute;
    top: 45%;
    margin-top: 7px;
    text-decoration: none;
    border: none;
    width: 40px;
    text-align: center;
    background-color: rgba(233, 233, 233, 0.3);
    box-shadow: 0 0 15px rgba(233, 233, 233, 0.5);
  }
`;

const mapStateToProps = store => {
  return {
    stationId: store.infoSelectionTable.pluvioId,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeDateFromAction: dateFrom => dispatch(changeDateFrom(dateFrom)),
    changeDateToAction: dateTo => dispatch(changeDateTo(dateTo)),
    changeLoadedToAction: loadedTo => dispatch(changeLoadedTo(loadedTo)),
    changeLoadedFromAction: loadedFrom => dispatch(changeLoadedFrom(loadedFrom))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PluvioInfoChart);
