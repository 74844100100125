import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <h1>
        Страница не найдена. Вернуться на <Link to="/">главную?</Link>
      </h1>
    );
  }
}

export default NotFound;
