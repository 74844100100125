import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { changeStation, changeRegion } from "../../../actions/actions";
import store from "../../../store";

function constructColumns() {
  return [
    {
      Header: "Имя",
      accessor: "name",
      minWidth: 315,
      Cell: props => <span>{props.original.station.name}</span>
    },
    {
      Header: "#",
      accessor: "id",
      Cell: props => <span>{props.original.station.id}</span>
    },
    {
      Header: "Координаты",
      accessor: "coord",
      minWidth: 150,
      Cell: props => (
        <span
          onClick={() => {
            store.dispatch(
              changeStation({
                lat: props.original.station.lat,
                lon: props.original.station.lon
              })
            );
            console.log("store.getState()", store.getState().stationMap.coord);
          }}
        >{`${props.original.station.lat}, ${props.original.station.lon}`}</span>
      )
    },
    {
      Header: "Заряд",
      accessor: "power",
      Cell: props => (
        <span style={props.original.power < 13.2 ? { color: "red" } : {}}>
          {props.original.power ? `${props.original.power} B` : ""}
        </span>
      )
    },
    {
      Header: "Последние данные",
      accessor: "lastMessage",
      minWidth: 150,
      Cell: props => (
        <span className="wrap">
          {props.original.lastMessage !== null
            ? moment(props.original.lastMessage).format("YYYY.MM.DD HH:mm")
            : " - "}
        </span>
      )
    },
    {
      Header: "Действия",
      accessor: "actions",
      minHeight: 100,
      sortable: false,
      Cell: props => (
        <Link
          to={`pluvioinfo/${props.original.station.id}`}
          className="details"
          onClick={() => {
            store.dispatch(
              changeStation({
                lat: props.original.station.lat,
                lon: props.original.station.lon
              })
            );
            store.dispatch(changeRegion(props.original.id));
            console.log("store.getState()", store.getState());
          }}
        >
          {props.original.message ? "Подробнее" : ""}
        </Link>
      )
    }
  ];
}

export default constructColumns;
