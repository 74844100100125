import { combineReducers } from "redux";
import { infoTableReducer } from "./infoTableReducer";
import { infoSelectionTableReducer } from "./infoSelectionTableReducer";
import { dataSorterReducer } from "./dataSorterReducer";
import { stationMapReducer } from "./stationMapReducer";
import { overviewTableMapReducer } from "./overviewTableMapReducer";

export const reducer = combineReducers({
  infoTable: infoTableReducer,
  infoSelectionTable: infoSelectionTableReducer,
  dataSorter: dataSorterReducer,
  stationMap: stationMapReducer,
  overviewTableMap: overviewTableMapReducer
});
