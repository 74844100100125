const initialState = {
  coord: { lat: 59.934, lon: 30.194 }
};

export function stationMapReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_STATION":
      return {
        ...state,
        coord: { lat: action.payload.lat, lon: action.payload.lon }
      };

    default:
      return state;
  }
}
