import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { levelsPaginationMessages } from "./Queries";
import InfoTable from "../../InfoTable";

class LevelInfoTable extends Component {
  state = {
    variables: {}
  };

  render() {
    const selectedId = this.props.id;

    const { dateFrom, dateTo } = this.props;
    const queryTag = levelsPaginationMessages;

    const columns = [
      // {
      //   Header: "#",
      //   accessor: "count",
      //   maxWidth: 60
      // },
      // {
      //   Header: "Message ID",
      //   accessor: "id"
      // },
      {
        Header: "Время сообщения",
        accessor: "measured_at",
        minWidth: 150,
        Cell: props => (
          <span>
            {moment(props.original.measuredAt * 1000).format("DD.MM.YY, HH:mm")}
          </span>
        )
      },
      {
        Header: props => <span className="wrap">LW</span>,
        accessor: "LW",
        minWidth: 150,
        Cell: props => <span>{props.original.LW}</span>
      },
      {
        Header: "TW",
        accessor: "TW",
        Cell: props => <span>{props.original.TW}</span>
      },
      {
        Header: "LWBS",
        accessor: "LWBS",
        Cell: props => <span>{props.original.LWBS}</span>
      },
      {
        Header: "LWAB",
        accessor: "LWAB",
        Cell: props => <span>{props.original.LWAB}</span>
      }
    ];

    return (
      <StyledTable className="InfoTable">
        <InfoTable
          key={selectedId}
          columns={columns}
          queryTag={queryTag}
          variables={{
            token: localStorage.getItem("token"),
            stationId: selectedId,
            since: moment(dateFrom).format("YYYY-MM-DDT00"),
            to: moment(dateTo).format("YYYY-MM-DDT23:59")
          }}
        />
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  /* border-collapse: collapse; */
  /* text-align: center; */
  /* color: #323232; */
  /* border: none; */
  /* width: 100%; */
  grid-row: 2;
  margin-top: 50px;
`;

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo
  };
};

export default connect(mapStateToProps)(LevelInfoTable);
