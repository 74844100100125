import React, { Component } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { ME_QUERY } from '../../../../queries/queries';
import { getToken } from '../../../../utils/auth';

class CurrentUser extends Component {
  state = {};

  render() {
    return (
      <Query query={ME_QUERY} variables={{ token: getToken() }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>error...</p>;

          return (
            <StyledUsername>
              <div className="username">
                <span>Здравствуйте, {data.me.username}.</span>
                <button
                  className="logoutbtn"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  LogOut
                </button>
              </div>
            </StyledUsername>
          );
        }}
      </Query>
    );
  }
}

const StyledUsername = styled.div`
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 0;
  /* grid-column: 12; */

  .username {
    display: flex;
    grid-column: 12;
    margin-top: 16px;
    border: 1px solid #7d9cb8;
    border-radius: 3px;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 60px;
    width: 170px;
    background-color: white;
    box-shadow: 15px 15px 10px rgba(154, 147, 140, 0.5);
  }
  span {
    color: #2a455c;
    font-weight: 600;
  }

  .logoutbtn {
    background-color: white;
    color: #2a455c;
  }
`;

export default CurrentUser;
