import React, { Component } from "react";
import ReactTable from "react-table";
import { ApolloConsumer } from "react-apollo";
import styled from "styled-components";
import gql from "graphql-tag";

export default class InfoTable extends Component {
  state = {
    dataArr: [],
    pages: null,
    loading: false,
    pageSize: 10,
    page: 1,
    sortVar: "measured_at"
  };

  render() {
    const { dataArr, pages } = this.state;

    const { columns, queryTag, variables } = this.props;

    const queryName = queryTag.definitions[0].name.value;

    const LOG_ERROR_MUTATION = gql`
      mutation(
        $exceptionType: String!
        $level: String!
        $message: String
        $source: String!
        $token: String!
      ) {
        addLogRecord(
          token: $token
          exceptionType: $exceptionType
          level: $level
          message: $message
          source: $source
        ) {
          log {
            id
          }
        }
      }
    `;

    return (
      <StyledTable>
        <div>
          <ApolloConsumer>
            {client => (
              <ReactTable
                manual
                loading={this.state.loading}
                data={dataArr}
                columns={columns}
                onSortedChange={(newSorted, column, shiftKey) => {
                  this.setState({
                    sortVar: newSorted[0].desc
                      ? newSorted[0].id
                      : `-${newSorted[0].id}`
                  });
                }}
                onFetchData={e => {
                  client
                    .query({
                      query: queryTag,
                      variables: {
                        ...variables,
                        page: e.page + 1,
                        pageCount: e.pageSize,
                        filterSort: { sort: [this.state.sortVar] }
                      }
                    })
                    .then(data => {
                      this.setState({
                        dataArr: data.data[queryName].objects,
                        pages: data.data[queryName].pages,
                        loading1: false,
                        pageSize: e.pageSize
                      });
                    })
                    .catch(err => {
                      client.mutate({
                        mutation: LOG_ERROR_MUTATION,
                        variables: {
                          token: localStorage.getItem("token"),
                          level: "error",
                          exceptionType: `${queryName} error`,
                          source: "Admin",
                          message: err
                        }
                      });
                    });
                }}
                pages={pages}
                previousText={"Назад"}
                nextText={"Вперед"}
                pageText={"Страница"}
                rowsText={"строк"}
                ofText={"из"}
              />
            )}
          </ApolloConsumer>
        </div>
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  border-collapse: collapse;
  text-align: center;
  color: #323232;
  border: none;
  width: 100%;
  margin-top: 45px;
  grid-column: 2 / span 8;

  .rt-table {
    grid-column: 2 / span 8;
    background-color: white;
  }

  thead {
    position: relative;
    font: 300 16px/20px Tahoma, sans-serif;
    background-color: #7d9cb8;
    color: #fff;
    height: 50px;
  }

  .rt-thead {
    height: 25px;
  }

  div {
    min-height: 45px;
  }

  th {
    border: 1px solid #7d9cb8;
    padding: 5px;
    white-space: normal;
    height: 40px;
  }

  .rt-tr-group:nth-child(2n) {
    background: rgb(234, 250, 255);
  }

  td {
    border: 1px solid #7d9cb8;
    padding: 5px;
    white-space: normal;
  }

  .rt-table {
    z-index: 1;
  }

  .rt-resizable-header-content {
    border: none;
  }

  .wrap {
    white-space: normal;
    height: 300px;
  }
`;
