import React, { Component } from "react";
import { GridContainer } from "../../../styles/GridContainer";
import NavigationMenu from "../../NavigationMenu";
import SourcesHeader from "../SourcesHeader";

class Benthal extends Component {
  state = {};
  render() {
    return (
      <GridContainer>
        <NavigationMenu />
        <SourcesHeader />
      </GridContainer>
    );
  }
}

export default Benthal;
