import React, { Component } from "react";
import { GridContainer } from "../../../../styles/GridContainer";
import NavigationMenu from "../../../NavigationMenu";
import SourcesHeader from "../../SourcesHeader";
import CurrentUser from "../../ams/queries/CurrentUser";
import styled from "styled-components";
import StationMap from "../../../map/StationMap";
import OverviewTable from "../../../OverviewTable";
import { radLastMessages } from "../Queries";
import { columns } from "./radiationOverviewColumns";

class RadiationOverview extends Component {
  state = {};
  render() {
    return (
      <RadiationDiv>
        <GridContainer>
          <NavigationMenu />
          <SourcesHeader />
          <CurrentUser />
          <div className="map">
            <StationMap queryTag={radLastMessages} />
          </div>
          <div className="overviewTable">
            <OverviewTable queryTag={radLastMessages} columns={columns} />
          </div>
        </GridContainer>
      </RadiationDiv>
    );
  }
}

const RadiationDiv = styled.div`
  .map {
    grid-column: 3 / span 9;
    display: block;
    margin-top: 1%;
    height: 400px;
    border: solid 1px #7d9cb8;
  }

  .overviewTable {
    grid-column: 3 / span 9;
    grid-row: 3;
  }
`;

export default RadiationOverview;
