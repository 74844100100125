import React, { Component } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import { changeRegion, changeStation } from "../../../../actions/actions";
import { radStations } from "../Queries";

class RadiationStationSelection extends Component {
  handleChange = e => {
    this.props.changeRegionAction(`${e.target.value}`);
  };

  componentDidMount() {
    this.props.changeRegionAction(`${this.props.initid}`);
  }

  state = {};
  render() {
    const { changeStationAction } = this.props;

    console.log("initid", this.props.initid);

    const variables = {
      token: localStorage.getItem("token")
    };

    const StationData = () => (
      <Query query={radStations} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <option>{loading}</option>;
          if (error) return console.log(error) + <option>Error :</option>;

          const options = data.radStations.map(({ name, id }) => (
            <option key={name} value={id}>
              {name} {id}
            </option>
          ));
          const sobj = data.radStations.find(v => v.id === this.props.id);

          function showStation(e) {
            const currStation = data.radStations.find(
              v => v.id === e.target.value
            );

            console.log("currStation", currStation);
            changeStationAction({
              lat: currStation.lat,
              lon: currStation.lon
            });
          }

          return (
            <select
              className="selectid"
              defaultValue={sobj != null ? sobj.id : "2020"}
              onChange={e => {
                this.handleChange(e);
                showStation(e);
              }}
            >
              {options}
            </select>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeRegionAction: id => dispatch(changeRegion(id)),
    changeStationAction: coord => dispatch(changeStation(coord))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RadiationStationSelection);
