import gql from "graphql-tag";

const ME_QUERY = gql`
  query($token: String!) {
    me(token: $token) {
      lastLogin
      username
    }
  }
`;

export { ME_QUERY };
