import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { pluvioPaginationMessages } from "../Queries";
import OverviewTable from "../../../InfoTable";

class PluvioInfoTable extends Component {
  state = {};

  render() {
    let { dateFrom, dateTo } = this.props;

    const selectedId = this.props.pluvioId;

    const variables = {
      token: localStorage.getItem("token"),
      stationId: selectedId,
      since: moment(dateFrom).format("YYYY-MM-DDT00"),
      to: moment(dateTo).format("YYYY-MM-DDT23:59:59")
    };

    const columns = [
      {
        Header: "measuredAt",
        accessor: "measured_at",
        minWidth: 150,
        Cell: props => (
          <span>
            {moment(props.original.measuredAt * 1000).format("DD.MM.YY, HH:mm")}
          </span>
        )
      },
      {
        Header: (
          <span>
            accumulated <br /> RtNrt
          </span>
        ),
        accessor: "accumulated_rt_nrt",
        Cell: props => <span>{props.original.accumulatedRtNrt}</span>
      },
      {
        Header: (
          <span>
            accumulated <br /> RtNrt Min
          </span>
        ),
        accessor: "accumulated_rt_nrt1_min",
        width: 100,
        Cell: props => <span>{props.original.accumulatedRtNrt1Min}</span>
      },
      {
        Header: (
          <span>
            accumulated <br /> Total Nrt
          </span>
        ),
        width: 100,
        accessor: "accumulated_total_nrt",
        Cell: props => <span>{props.original.accumulatedTotalNrt}</span>
      },
      {
        Header: (
          <span>
            bucket <br /> Content Nrt
          </span>
        ),
        width: 100,
        accessor: "bucket_content_nrt",
        Cell: props => <span>{props.original.bucketContentNrt}</span>
      },
      {
        Header: "intensity",
        width: 100,
        accessor: "intensity",
        Cell: props => <span>{props.original.intensity}</span>
      },
      {
        Header: "daily Sum",
        width: 100,
        accessor: "daily_sum",
        Cell: props => <span>{props.original.dailySum}</span>
      },
      {
        Header: (
          <span>
            power <br /> Supply <br /> Pluvio2
          </span>
        ),
        accessor: "power_supply_pluvio2",
        Cell: props => <span>{props.original.powerSupplyPluvio2}</span>
      },
      {
        Header: props => (
          <span>
            relative
            <br /> Air
          </span>
        ),
        width: 70,
        accessor: "relative_air",
        Cell: props => <span>{props.original.relativeAir}</span>
      },
      {
        Header: (
          <span>
            status
            <br /> Heating <br /> Pluvio2
          </span>
        ),
        accessor: "status_heating_pluvio2",
        Cell: props => <span>{props.original.statusHeatingPluvio2}</span>
      },
      {
        Header: props => (
          <span>
            status <br /> Pluvio2
          </span>
        ),
        width: 70,
        accessor: "status_pluvio2",
        Cell: props => <span>{props.original.statusPluvio2}</span>
      },
      {
        Header: "temperature",
        width: 90,
        accessor: "temperature",
        Cell: props => <span>{props.original.temperature}</span>
      },
      {
        Header: (
          <span>
            temperature
            <br /> Loadcell <br /> Pluvio2Ec
          </span>
        ),
        accessor: "temperature_loadcell_pluvio2",
        Cell: props => (
          <span>{props.original.temperatureLoadcellPluvio2Ec}</span>
        )
      },
      {
        Header: (
          <span>
            temperature
            <br /> RimHeating <br /> Pluvio2Ec
          </span>
        ),
        accessor: "temperature_rim_heating_pluvio2",
        Cell: props => (
          <span>{props.original.temperatureRimHeatingPluvio2Ec}</span>
        )
      }
    ];

    return (
      <>
        <StyledTable>
          <OverviewTable
            key={selectedId}
            columns={columns}
            queryTag={pluvioPaginationMessages}
            variables={variables}
          />
        </StyledTable>
      </>
    );
  }
}

const StyledTable = styled.div`
  /* border-collapse: collapse;
  text-align: center;
  color: #323232;
  border: none;*/
  /* width: 1550px; */
  margin-top: -300px;
  grid-column: 3 / span 9;

  .rt-thead {
    height: 55px;
  }
`;

const mapStateToProps = store => {
  return {
    pluvioId: store.infoSelectionTable.pluvioId,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo
  };
};

export default connect(mapStateToProps)(PluvioInfoTable);
