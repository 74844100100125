import gql from "graphql-tag";

export const BufrPaginationMessages = gql`
  query bufrPaginationMessages(
    $token: String!
    $stationId: String!
    $page: Int
    $pageCount: Int
    $filterSort: FilterSortInput
  ) {
    bufrPaginationMessages(
      token: $token
      stationId: $stationId
      page: $page
      pageCount: $pageCount
      filterSort: $filterSort
    ) {
      objects {
        measuredAt
        dataType
        speedMotion
        directionMotion
        layers
        heightAboveStation1
        heightAboveStation2
      }
      page
      pages
    }
  }
`;

export const bufrLastMessages = gql`
  query bufrLastMessages($token: String!) {
    bufrLastMessages(token: $token, isadmin: true) {
      station {
        lat
        lon
        height
        name
        id
      }
      message {
        id
        measuredAt
      }
    }
  }
`;

export const bufrStations = gql`
  query bufrStations($token: String!) {
    bufrStations(token: $token) {
      name
      id
      lat
      lon
    }
  }
`;
