const initialState = {
  id: "26078",
  pluvioId: "0000001001",
  bufrId: "26065"
};

export function infoSelectionTableReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_REGION":
      return { ...state, id: action.payload };

    case "CHANGE_PLUVIO_ID":
      return { ...state, pluvioId: action.payload };

    case "CHANGE_BUFR_ID":
      return { ...state, bufrId: action.payload };

    default:
      return state;
  }
}
