import moment from "moment";
import { Link } from "react-router-dom";
import React from "react";
import store from "../../../store";
import { changeStation, changeRegion } from "../../../actions/actions";

export const columns = [
  {
    Header: "Имя",
    accessor: "name",
    minWidth: 150,
    Cell: props => <span>{props.original.station.name}</span>
  },
  {
    Header: "#",
    accessor: "id",
    Cell: props => <span>{props.original.station.id}</span>
  },
  {
    Header: "Координаты",
    accessor: "coord",
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        className="coord"
        onClick={() => {
          console.log(props.original);
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
        }}
      >{`${props.original.station.lat}, ${props.original.station.lon}`}</span>
    )
  },
  {
    Header: "Авто",
    accessor: "auto",
    sortable: false,
    Cell: props => (
      <span>
        {props.original.station.auto && (
          <span style={{ fontWeight: "bold", color: "green" }}>&#10004;</span>
        )}
        {props.original.station.manual && (
          <span style={{ color: "red" }}>&#10005;</span>
        )}
      </span>
    )
  },
  {
    Header: "Заряд",
    accessor: "battery",
    sortable: false,
    Cell: props => (
      <span
        style={
          props.original.message
            ? props.original.message.batteryCharge < 13.2
              ? { color: "red" }
              : {}
            : {}
        }
      >
        {props.original.message
          ? props.original.message.batteryCharge
            ? props.original.message.batteryCharge + " B"
            : ""
          : ""}
      </span>
    )
  },
  {
    Header: "Последние данные",
    accessor: "lastMessage",
    minWidth: 150,
    Cell: props => (
      <span>
        {props.original.message
          ? moment(props.original.message.measuredAt * 1000).format(
              "YYYY.MM.DD HH:mm"
            )
          : " - "}
      </span>
    )
  },
  {
    Header: "Действия",
    accessor: "actions",
    minHeight: 100,
    sortable: false,
    Cell: props => (
      <Link
        to={`amsinfo/${props.original.station.id}`}
        className="details"
        onClick={() => {
          store.dispatch(
            changeStation({
              lat: props.original.station.lat,
              lon: props.original.station.lon
            })
          );
          store.dispatch(changeRegion(props.original.station.id));
        }}
      >
        {props.original.message ? "Подробнее" : ""}
      </Link>
    )
  }
];
