import React, { Component } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import layer from "../../images/menu-pics/layers.png";
import ams from "../../images/menu-pics/meteostation.png";
import radar from "../../images/menu-pics/meteostation_radar.png";
import radiation from "../../images/menu-pics/radiation.png";
import precipitation from "../../images/menu-pics/precipitation.png";
import water_level from "../../images/menu-pics/water_level.png";
import store from "../../store";
import { changeStation } from "../../actions/actions";

class SourcesHeader extends Component {
  state = {};

  render() {
    const currentUrl = window.location.pathname;

    const isActive = (match, location) => {
      if (currentUrl.includes(location.path)) {
        return true;
      }
      return false;
    };

    return (
      <SourcesHeaderTemplate
        className="sourcesHeaderTemplate"
        onClick={e => {
          store.dispatch(
            changeStation({
              lat: 59.934,
              lon: 30.194
            })
          );
        }}
      >
        <NavLink
          activeClassName="active"
          to="/sources/amsoverview"
          isActive={isActive}
          location={{ path: "/ams" }}
        >
          <li>
            <i className="ams" />
            АМС
          </li>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/sources/pluviooverview"
          isActive={isActive}
          location={{ path: "/pluvio" }}
        >
          <li>
            <i className="precipitation" />
            Осадкомеры
          </li>
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/sources/leveloverview"
          isActive={isActive}
          location={{ path: "/level" }}
        >
          <li>
            <i className="water-level" />
            Уровнемеры
          </li>
        </NavLink>
        <NavLink
          onClick={e => {}}
          activeClassName="active"
          isActive={isActive}
          to="/sources/bufroverview"
          location={{ path: "/bufr" }}
        >
          <li>
            <i className="radar" />
            Радары
          </li>
        </NavLink>
        <NavLink
          activeClassName="active"
          isActive={isActive}
          to="/sources/radiationoverview"
          location={{ path: "/radiation" }}
        >
          <li>
            <i className="radiation" />
            Радиационные
          </li>
        </NavLink>
        <NavLink
          onClick={e => {
            e.preventDefault();
          }}
          activeClassName="active"
          to="/sources/benthal"
        >
          <li
            style={{
              backgroundColor: "rgba(200, 200, 200, 0.9)"
            }}
          >
            <i className="layer" />
            Донные
          </li>
        </NavLink>
      </SourcesHeaderTemplate>
    );
  }
}

const SourcesHeaderTemplate = styled.ul`
  display: flex;
  grid-column: 2 / span 8;
  grid-row: 1;
  width: auto;
  margin-left: 25%;
  z-index: 1;
  margin-top: 1px;

  .ams {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: flex;
    background: url(${ams}) no-repeat;
  }

  .water-level {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: block;
    background: url(${water_level}) no-repeat;
  }

  .precipitation {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: block;
    background: url(${precipitation}) no-repeat;
  }

  .radar {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: block;
    background: url(${radar}) no-repeat;
  }

  .radiation {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: block;
    background: url(${radiation}) no-repeat;
  }

  .layer {
    width: 30px;
    height: 30px;
    margin: 0 0 5px 35px;
    display: block;
    background: url(${layer}) no-repeat;
  }

  li {
    display: block;
    padding: 5px;
    width: auto;
    text-align: center;
    min-width: 100px;
    min-height: 30px;
    border: 1px solid #7d9cb8;
    margin-right: -1px;
    margin-bottom: -1px;
    text-decoration: none;
    background-color: white;
    box-shadow: 15px 15px 10px rgba(154, 147, 140, 0.5);
  }
  a {
    display: block;
    height: 50;
    text-decoration: none;
    color: #2a455c;
  }

  .active li {
    background-color: #7d9cb8;
    color: white;
    font-weight: bold;
  }

  .i {
    color: red;
  }
`;

export default SourcesHeader;
