import moment from "moment";
import React from "react";

// let filterParam = (dateFrom, dateTo) => {
//   const daySpan = moment(dateTo) - moment(dateFrom);

//   if (daySpan > day * 20) {
//     return 80;
//   }
//   if (day * 10 <= daySpan && daySpan <= 20 * day) {
//     return 50;
//   }
//   if (daySpan < day * 10 && daySpan > day * 3) {
//     return 20;
//   }
//   if (daySpan <= day * 3) {
//     return 10;
//   }
// };

export const plotConstructor = (chunks, quantity) => {
  let sorted = chunks.sort((a, b) => b.chunk - a.chunk);
  const validObj = sorted.find((v, i) => v.chunk === chunks.length - i - 1);
  if (!validObj) {
    return new Array(quantity).fill([{ x: 0, y: 0 }]);
  }
  const ret = sorted
    .reverse()
    .slice(0, validObj.chunk + 1)
    .map(ch => ch.data)
    .reduce(
      (a, data) =>
        a.map((d, i) =>
          d.concat(data[i].map(di => ({ x: di.x * 1000, y: di.y })))
        ),
      new Array(quantity).fill([])
    );
  return ret;
};

export const windDirectionHandler = (msgs, dateFrom, dateTo) => {
  let arr = [];

  arr = msgs
    .filter((e, i) => e.y != null) //i % filterParam(dateFrom, dateTo) === 0)
    .map(point => {
      switch (point.y) {
        case 0:
          return {
            x: point.x,
            y: 0,
            customComponent: "circle",
            size: 10,
            style: { stroke: "black", fill: "white" },
            z: 0
          };
        case 99:
          return {
            x: point.x,
            y: 0,
            customComponent: "circle",
            size: 20,
            style: { stroke: "black", fill: "white" },
            z: 0
          };
        default:
          const val = point.y; //* 10;
          return {
            x: point.x,
            y: 0,
            customComponent: (row, positionInPixels) => {
              return (
                <g
                  transform={` scale(0.06) rotate(${val - 90} 270 270 )`}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  // style="enable-background:new 0 0 512 512;"
                >
                  <rect
                    y="227.558"
                    style={{ fill: "#50C8EF" }}
                    width="455.117"
                    height="56.883"
                  />
                  <polygon
                    style={{ fill: "#50C8EF" }}
                    points="278.566,390.774 250.117,341.507 398.226,256 250.117,170.491 278.566,121.226 512,256 	"
                  />
                </g>
              );
            },
            z: val
          };
      }
    });
  return arr;
};

export function stationStatus(message, auto, manual, timeLLLL = 120) {
  const { measuredAt, batteryCharge } = message || {};

  let color, stroke;

  const interval = moment()
    .subtract(timeLLLL, "minutes")
    .unix();

  if (batteryCharge < 13.2) {
    color = "red";
  } else {
    color = "green";
  }
  if (measuredAt < interval) {
    stroke = "dashed";
  } else {
    stroke = "solid";
  }

  return { color: color, stroke: stroke, auto: auto, manual: manual };
}
