import "../../node_modules/react-vis/dist/style.css";
import "moment/locale/ru";
import { XYPlot, PolygonSeries, XAxis, YAxis, GradientDefs } from "react-vis";

import React, { Component } from "react";

function buildTriangle(sideWidth, lowerLeftCoord) {
  const { x, y } = lowerLeftCoord;

  const triangle = [
    [
      { x, y },
      { x, y: y + sideWidth },
      { x: x + sideWidth, y },
      { x: x + sideWidth, y: y + sideWidth }
    ]
  ];

  if (
    true
    // sideWidth < 0.5
  ) {
    return triangle;
  }

  const newWidth = sideWidth * 0.5;

  const a = buildTriangle(newWidth, lowerLeftCoord);

  const b = buildTriangle(newWidth, { x: x + sideWidth, y });

  const c = buildTriangle(newWidth, { x, y: y + sideWidth });

  const d = buildTriangle(newWidth, { x: x + sideWidth, y: y + sideWidth });

  return triangle
    .concat(a)
    .concat(b)
    .concat(c)
    .concat(d);
}

const triangles = buildTriangle(5, { x: 0, y: 0 });

export class Test extends Component {
  state = {
    hoveredIndex: false
  };

  render() {
    const { hoveredIndex } = this.state;

    return (
      <XYPlot width={300} height={300}>
        <GradientDefs>
          <radialGradient
            id="grad1"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            {/* <stop offset="0%" stopColor="#829AE3" stopOpacity="1" /> */}
            <stop offset="100%" stopColor="#12939A" stopOpacity="1" />
          </radialGradient>
        </GradientDefs>
        <XAxis />
        <YAxis />
        {triangles.map((triangle, index) => {
          return (
            <PolygonSeries
              key={`triangle-${index}`}
              data={triangle}
              onSeriesMouseOver={() => this.setState({ hoveredIndex: index })}
              onSeriesMouseOut={() => this.setState({ hoveredIndex: false })}
              color={index !== hoveredIndex ? "url(#grad1)" : null}
              style={{
                strokeWidth: 0.5,
                strokeOpacity: 1,
                opacity: 0.5,
                fill: index === hoveredIndex ? "#EF5D28" : null
              }}
            />
          );
        })}
      </XYPlot>
    );
  }
}

export default Test;
