import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import * as ams from "../../../../utils/ams-utils";
import { amsPaginationMessages } from "../Queries";
import bar0 from "../../../../images/bar/bar0.svg";
import bar1 from "../../../../images/bar/bar1.svg";
import bar2 from "../../../../images/bar/bar2.svg";
import bar3 from "../../../../images/bar/bar3.svg";
import bar4 from "../../../../images/bar/bar4.svg";
import bar5 from "../../../../images/bar/bar5.svg";
import bar6 from "../../../../images/bar/bar6.svg";
import bar7 from "../../../../images/bar/bar7.svg";
import bar8 from "../../../../images/bar/bar8.svg";
import InfoTable from "../../../InfoTable";

class AmsInfoTable extends Component {
  state = {
    param: "-dd"
  };

  render() {
    const selectedId = this.props.id;

    let { param } = this.state;

    const { dateFrom, dateTo } = this.props;
    const queryTag = amsPaginationMessages;

    const variables = {
      token: localStorage.getItem("token"),
      stationId: selectedId,
      since: moment(dateFrom).format("YYYY-MM-DDT00"),
      to: moment(dateTo).format("YYYY-MM-DDT23:59"),
      filterSort: { sort: [param] }
    };

    console.log("param", param);

    const columns = [
      // {
      //   Header: "#",
      //   accessor: "count",
      //   maxWidth: 60
      // },
      // {
      //   Header: "Message ID",
      //   accessor: "id"
      // },
      {
        Header: props => (
          <span
          // onClick={e => {
          //   this.setState({
          //     param: param === "-measured_at" ? "measured_at" : "-measured_at"
          //   });
          // }}
          >
            Время сообщения
          </span>
        ),
        accessor: "measured_at",
        minWidth: 150,
        Cell: props => (
          <span>
            {moment(props.original.measuredAt * 1000).format("DD.MM.YY, HH:mm")}
          </span>
        )
      },
      {
        Header: props => (
          <span className="wrap">Среднее направление ветра</span>
        ),
        accessor: "dd",
        minWidth: 150,
        Cell: props =>
          props.original.dd ? <span>{props.original.dd}°</span> : ""
      },
      {
        Header: "V ветра, м/с",
        accessor: "ff",
        Cell: props =>
          props.original.ff ? <span>{props.original.ff} м/с</span> : ""
      },
      {
        Header: "Воздух, C°",
        accessor: "TTT_1",
        Cell: props => <span>{props.original.TTT1}</span>
      },
      {
        Header: "Давление гПа",
        accessor: "P0P0P0P0_3",
        Cell: props =>
          props.original.P0P0P0P03 ? (
            <span>{props.original.P0P0P0P03} гПа</span>
          ) : (
            ""
          )
      },
      {
        Header: props => <span className="wrap">Приведенное давление гПа</span>,
        accessor: "PPPP_4",
        Cell: props =>
          props.original.PPPP4 ? <span>{props.original.PPPP4} гПа</span> : ""
      },
      {
        Header: props => <span className="wrap">Барическая тенденция</span>,
        accessor: "ppp_5",
        Cell: props => {
          if (props.original.a5 && props.original.ppp5 != null) {
            const barometricSymbols = {
              0: bar0,
              1: bar1,
              2: bar2,
              3: bar3,
              4: bar4,
              5: bar5,
              6: bar6,
              7: bar7,
              8: bar8
            };

            return (
              <span>
                {ams.getPressureTrendValue(
                  props.original.a5,
                  props.original.ppp5
                )}{" "}
                <img
                  src={barometricSymbols[props.original.a5]}
                  style={{ height: "25px", width: "25px" }}
                  alt={`bar${props.original.a5}`}
                />
              </span>
            );
          }
          return "";
        }
        // Cell: props => (
        //   <span>
        //     {console.log("ppp5", props.original.ppp5)}
        //     {props.original.ppp5}
        //   </span>
        // )
      },
      {
        Header: "Количество осадков",
        accessor: "RRR_6",
        minWidth: 150,
        Cell: props => (
          <span>{props.original.RRR6 ? `${props.original.RRR6} мм` : ""} </span>
        )
      },
      {
        Header: "Заряд",
        accessor: "battery_charge",
        Cell: props => (
          <span
            style={props.original.batteryCharge < 13.2 ? { color: "red" } : {}}
          >
            {props.original.batteryCharge
              ? props.original.batteryCharge + " B"
              : ""}
          </span>
        )
      },
      {
        Header: "Исходные данные",
        accessor: "source",
        minWidth: 140,
        sortable: false,
        Cell: props => {
          if (!props.original.filesIds) return <span>-</span>;
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                process.env.REACT_APP_SERVER_ENDPOINT
              }api/files/ams/?token=${localStorage.getItem("token")}&id=${
                props.original.filesIds[0]
              }`}
            >
              Посмотреть сообщение
            </a>
          );
        }
      }
    ];

    return (
      <StyledTable className="InfoTable">
        <InfoTable
          key={selectedId}
          columns={columns}
          queryTag={queryTag}
          variables={variables}
        />
      </StyledTable>
    );
  }
}

const StyledTable = styled.div`
  margin-top: 50px;
`;

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo
  };
};

export default connect(mapStateToProps)(AmsInfoTable);
