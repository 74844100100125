import React, { Component } from "react";
import { GridContainer } from "../../../styles/GridContainer";
import NavigationMenu from "../../NavigationMenu";
import SourcesHeader from "../SourcesHeader";
import AmsInfoTable from "./queries/AmsInfoTable";
import AmsDataSorter from "./AmsDataSorter";
import { connect } from "react-redux";
import { changeRegion } from "../../../actions/actions";
import styled from "styled-components";
import CurrentUser from "./queries/CurrentUser";
import InfoChart from "./queries/InfoChart";

class AMSInfo extends Component {
  state = {};

  render() {
    const { id } = this.props.match.params;

    return (
      <StylingDiv>
        <GridContainer>
          <CurrentUser />
          <NavigationMenu />
          <SourcesHeader />
          <AmsDataSorter id={id} />
          <div className="amsInfoChart">
            <InfoChart />
          </div>
          <AmsInfoTable />
        </GridContainer>
      </StylingDiv>
    );
  }
}

const StylingDiv = styled.div`
  .amsInfoChart {
    grid-column: 3 / span 9;
    height: 600px;
  }

  .DataSorter {
    grid-column: 3 / span 9;
    grid-row: 2;
    margin-top: 1%;
  }

  .InfoTable {
    width: 100%;
    grid-column: 3 / span 9;
    grid-row: 4;
    margin-top: 600px;
  }
`;

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeRegionAction: id => dispatch(changeRegion(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AMSInfo);
