import React, { Component } from "react";
import { LevelsChartGen } from "react-meteo-chart";
import moment from "moment";
import { connect } from "react-redux";
import { changeDateFrom, changeDateTo } from "../../../actions/actions";

class LevelInfoChart extends Component {
  render() {
    const { id, dateFrom, dateTo } = this.props;
    return (
      <div>
        <LevelsChartGen
          token={localStorage.getItem("token")}
          stationId={id}
          height={300}
          dateFrom={moment(dateFrom).format("YYYY-MM-DDTHH:mm")}
          dateTo={moment(dateTo).format("YYYY-MM-DDTHH:mm")}
          isAdmin={true}
          loadMessage={false}
        />
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeDateFromAction: dateFrom => dispatch(changeDateFrom(dateFrom)),
    changeDateToAction: dateTo => dispatch(changeDateTo(dateTo))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelInfoChart);
