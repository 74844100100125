import React, { Component } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import { changeBufrId, changeStation } from "../../../../actions/actions";
import { bufrStations } from "../Queries";

class BufrStationSelection extends Component {
  handleChange = e => {
    this.props.changeBufrIdAction(`${e.target.value}`);
  };

  componentDidMount() {
    this.props.changeBufrIdAction(`${this.props.initid}`);
  }

  state = {};
  render() {
    const variables = {
      token: localStorage.getItem("token")
    };

    const { changeStationAction } = this.props;

    const StationData = () => (
      <Query query={bufrStations} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <option>{loading}</option>;
          if (error) return console.log(error) + <option>Error :</option>;

          const options = data.bufrStations.map(({ name, id }) => (
            <option key={name} value={id}>
              {name} {id}
            </option>
          ));
          const sobj = data.bufrStations.find(v => v.id === this.props.bufrId);

          function showStation(e) {
            const currStattion = data.bufrStations.find(
              v => v.id === e.target.value
            );
            changeStationAction({
              lat: currStattion.lat,
              lon: currStattion.lon
            });
          }

          return (
            <select
              className="selectid"
              defaultValue={sobj != null ? sobj.id : "26065"}
              onChange={e => {
                this.handleChange(e);
                showStation(e);
              }}
            >
              {options}
            </select>
          );
        }}
      </Query>
    );

    return <StationData />;
  }
}

const mapStateToProps = store => {
  return {
    bufrId: store.infoSelectionTable.bufrId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStationAction: coord => dispatch(changeStation(coord)),
    changeBufrIdAction: id => dispatch(changeBufrId(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BufrStationSelection);
