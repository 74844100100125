import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { increaseNumber } from "./actions/actions";
import Shlyapa from "./components/Shlyapa";
import NavigationMenu from "./components/NavigationMenu";
import { GridContainer } from "./styles/GridContainer";

class App extends Component {
  render() {
    return (
      <GridContainer className="App">
        <Redirect to={{ pathname: "/sources/amsoverview" }} />
        <h1 style={h1Style}>Main Page</h1>
        <NavigationMenu />
        <Shlyapa />
      </GridContainer>
    );
  }
}

const h1Style = {
  margin: "100px"
};

const mapStateToProps = store => {
  return {
    name: store.name,
    number: store.number
  };
};

const mapDispatchToProps = dispath => {
  return {
    increaseNumberAction: () => dispath(increaseNumber())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
