import React, { Component } from "react";
import styled from "styled-components";

export default class MapLegend extends Component {
  render() {
    return (
      <MapLegendDiv>
        <div className="battery">
          <div className="red" />
          <span className="hint"> &lt; 13.2 B</span>
        </div>
        <div className="battery">
          <div className="green" />
          <span className="hint">&gt; 13.2 B</span>
        </div>
        <div className="dashTimeout">
          <div className="dash" />
          <span className="hint">t &lt; таймаут</span>
        </div>
        <div className="solidTimeout">
          <div className="solid" />
          <span className="hint">t &gt; таймаут</span>
        </div>
        <div className="auto">
          <span className="letters">A</span>
          <span className="hint">- авто</span>
        </div>
        <div className="auto">
          <span className="letters">M</span>
          <span className="hint"> - ручное</span>
        </div>
      </MapLegendDiv>
    );
  }
}

const MapLegendDiv = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  width: 600px;
  height: 30px;
  align-items: center;
  background-color: rgba(232, 236, 241, 0.6);
  justify-content: space-around;

  .dashTimeout,
  .solidTimeout,
  .auto,
  .battery {
    display: flex;
  }

  .letters {
    color: black;
    font-weight: bold;
  }

  .solid {
    border: 2px solid black;
    width: 10px;
    height: 10px;
  }

  .dash {
    border: 2px dashed black;
    width: 10px;
    height: 10px;
  }

  .red {
    width: 15px;
    height: 15px;
    background-color: red;
  }

  .green {
    width: 15px;
    height: 15px;
    background-color: green;
  }

  .hint {
    padding-left: 5px;
  }
`;
