import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import "../../../../../node_modules/react-vis/dist/style.css";
import {
  changeDateFrom,
  changeDateTo,
  changeLoadedFrom,
  changeLoadedTo
} from "../../../../actions/actions";
import moment from "moment";
import back from "../../../../images/navigation/back.svg";
import next from "../../../../images/navigation/next.svg";
import { AmsChartGen } from "react-meteo-chart";

class InfoChart extends Component {
  state = {
    cross: [{ x: 1487624400000, y: 9 }, { x: 1487624400000, y: 19 }],
    chunks: [],
    precipArr: []
  };

  checkId = undefined;
  dateFrom = undefined;
  dateTo = undefined;
  dataNeedLoad = false;

  countt = 0;

  render() {
    let { dateFrom, dateTo } = this.props;
    dateFrom += "T00:00";
    dateTo += "T23:59";

    const selectedId = this.props.id;

    const needReload =
      this.dateFrom !== this.props.dateFrom ||
      this.dateTo !== this.props.dateTo ||
      this.checkId !== this.props.id;

    if (needReload) {
      this.checkId = this.props.id;
      this.dateFrom = this.props.dateFrom;
      this.dateTo = this.props.dateTo;
      this.dataNeedLoad = true;
      this.setState(prevState => ({ chunks: [] }));
      return <></>;
    }

    const moveOnChart = action => {
      let newFrom = action
        ? moment(dateFrom)
            .add(2, "days")
            .format("YYYY-MM-DD")
        : moment(dateFrom)
            .subtract(2, "days")
            .format("YYYY-MM-DD");

      this.props.changeDateFromAction(newFrom);

      let newTo = action
        ? moment(dateTo)
            .add(2, "days")
            .format("YYYY-MM-DD")
        : moment(dateTo)
            .subtract(2, "days")
            .format("YYYY-MM-DD");

      this.props.changeDateToAction(newTo);
    };

    // const superArr = plotConstructor(chunks, 7);

    // superArr[4] = windDirectionHandler(superArr[4], dateFrom, dateTo);

    return (
      <>
        <ChartDiv>
          <AmsChartGen
            token={localStorage.getItem("token")}
            stationId={selectedId}
            height={300}
            dateFrom={moment(dateFrom).format("YYYY-MM-DDTHH:mm")}
            dateTo={moment(dateTo).format("YYYY-MM-DDTHH:mm")}
            isAdmin={true}
            maxPoints={200}
            loadMessage={false}
          />
          <button
            className="backward"
            onClick={() => {
              moveOnChart(false);
            }}
          >
            <img alt="back" src={back} />
          </button>
          <button
            className="forward"
            onClick={() => {
              moveOnChart(true);
            }}
          >
            <img alt="next" src={next} />
          </button>
        </ChartDiv>
      </>
    );
  }
}

const ChartDiv = styled.div`
  position: relative;
  .forward {
    right: 0;
  }

  .backward {
    margin-right: 100px;
  }

  button {
    position: absolute;
    top: 45%;
    margin-top: 7px;
    text-decoration: none;
    border: none;
    width: 40px;
    text-align: center;
    background-color: rgba(233, 233, 233, 0.3);
    box-shadow: 0 0 15px rgba(233, 233, 233, 0.5);
  }
`;

const mapStateToProps = store => {
  return {
    id: store.infoSelectionTable.id,
    dateFrom: store.dataSorter.dateFrom,
    dateTo: store.dataSorter.dateTo,
    loadedFrom: store.dataSorter.loadedFrom,
    loadedTo: store.dataSorter.loadedTo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeDateFromAction: dateFrom => dispatch(changeDateFrom(dateFrom)),
    changeDateToAction: dateTo => dispatch(changeDateTo(dateTo)),
    changeLoadedToAction: loadedTo => dispatch(changeLoadedTo(loadedTo)),
    changeLoadedFromAction: loadedFrom => dispatch(changeLoadedFrom(loadedFrom))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoChart);
