import styled from "styled-components";

import bg from "../../images/login/bg.jpg";
import { CenteredRow } from "./general";

const Background = styled.div`
  background: url(${bg}) #495d75 no-repeat;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  height: 100vh;
`;

const Header = styled(CenteredRow)`
  justify-content: space-between;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;

const Title = styled.p`
  font-family: "Europe", sans-serif;
  color: #afbee0;
  font-size: 24px;
  font-weight: 400;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

const LoginInputDataForm = styled(CenteredRow)`
  margin-top: 150px;
`;

export { Background, Header, Title, Logo, LoginInputDataForm };
