import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { GridContainer } from '../styles/GridContainer';

class Journal extends Component {
  state = {};
  render() {
    return (
      <GridContainer>
        <h1>Journal</h1>
        <NavigationMenu />
        <Link className="link" to="/">
          <h6>main page</h6>
        </Link>
        <ul>
          <li>Заданием периода вывода сообщений</li>
          <li>Фильтром по типу сообщения</li>
          <li>Фильтром по источнику сообщения</li>
        </ul>
      </GridContainer>
    );
  }
}

export default Journal;
