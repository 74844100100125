import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationMenu from './NavigationMenu';
import { GridContainer } from '../styles/GridContainer';

class Users extends Component {
  state = {};
  render() {
    return (
      <GridContainer>
        <h1>Users</h1>
        <NavigationMenu />
        <Link className="link" to="/">
          <h6>main page</h6>
        </Link>
      </GridContainer>
    );
  }
}

export default Users;
