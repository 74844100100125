import gql from "graphql-tag";

export const amsPaginationMessages = gql`
  query amsPaginationMessages(
    $token: String!
    $stationId: String!
    $page: Int
    $pageCount: Int
    $filterSort: FilterSortInput
  ) {
    amsPaginationMessages(
      token: $token
      stationId: $stationId
      page: $page
      pageCount: $pageCount
      filterSort: $filterSort
    ) {
      objects {
        TTT1
        a5
        ppp5
        P0P0P0P03
        RRR6
        tR6
        dd
        ff
        PPPP4
        measuredAt
        id
        filesIds
        batteryCharge
      }
      page
      pages
      hasNext
      hasPrev
    }
  }
`;

export const amsLastMessages = gql`
  query amsLastMessages($token: String!) {
    amsLastMessages(token: $token, isadmin: true) {
      station {
        id
        name
        lat
        lon
        height
        auto
        manual
      }
      message {
        measuredAt
        batteryCharge
      }
    }
  }
`;
